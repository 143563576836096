import {
  call,
  put,
  fork,
  takeLatest,
  all
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { browserHistory } from 'react-router';
import * as actions from 'ducks/auth';
import { fetchProfile } from 'ducks/profile';
import { load, loaded } from 'ducks/loading';
import { storeToken } from 'services/Request';
import * as Sentry from '@sentry/browser';

import * as api from 'services/api';
import moment from 'moment';

// const errorMessge = 'Please try again or refresh!';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2000,
  className: 'toast-style'
};

const evictedPaths = [ '/main','/oauth-integration/verification/shopify/', '/campaigns', '/billing-details', '/profile', '/checkout', '/aweberAuth','/authCode','/upgrade-plan','/checkoutpage'];

if (window.location.search && window.location.search.split('=').length > 0 && window.location.search.split('=')[0] == '?token') {
  const token = window.location.search.split('=')[1];
  localStorage.setItem('authToken', JSON.stringify({ 'token': token, 'expiresOn': moment().add(1, 'M') }));
}
export const removeAuthToken = () => localStorage.removeItem('authToken');

export function* isLoggedIn() {
  try {
    yield all([
      put(actions.fetchUser()),
      put(fetchProfile())
    ]);
  } catch(error) {
    // Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* checkTokenExists(action) {
  const token = action.token;
  try {
    if (token) {
      if(moment().isAfter(moment(token.expiresOn)))
        return yield call(logOut);
      return yield call(isLoggedIn);
    }
    yield call(logOut);
  } catch(error) {
    // Sentry.captureException(error);
    yield console.log(error);
  }

}

export function* logOut() {
  try {
    yield call(removeAuthToken);
    yield call(browserHistory.push, '/login');
    let beamer = document.getElementById('beamerSelector');
    if(beamer)
      beamer.style.display = 'none';
  } catch(error) {
    // Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* fetchUser() {
  try {
    yield put(load());
    const res = yield call(api.GET, 'user/me');
    if(!res.error) {
      const pathname = browserHistory.getCurrentLocation().pathname;
      console.log("pathname",pathname)
      if(window.location.href.includes('/campaigns?token')){
        yield put(actions.fetchUserSuccess(res));
        return
      }else if (evictedPaths.indexOf(pathname) == -1)
        yield call(browserHistory.push, res.path);
        console.log('pathname', res.path)
        yield put(actions.fetchUserSuccess(res));
    } else {
      Sentry.captureException(res.error);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield call(removeAuthToken);
    yield call(browserHistory.push, '/login');
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* updateUser(action) {
  try {
    yield put(load());
    const res = yield call(api.PUT, `user/${action.user.id}`, action.user);
    if(res.error)
      Sentry.captureException(res.error);
    else
      yield put(actions.fetchUserSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* fetchRoles() {
  try {
    yield put(load());
    const res = yield call(api.GET, 'users-permissions/roles');
    if(res.error)
      Sentry.captureException(res.error);
    else
      yield put(actions.fetchRolesSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* affiliate(action) {
  try {
    yield put(load());
    const res = yield call(api.GETAUTH, `user/sendmail/affiliate?name=${action.data.name}&email=${action.data.email}`);
    if(res.error)
      yield put(actions.affiliateError(res.message));
    else
      yield toast.info('Affiliate Request sent.', toastConfig);
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* demo(action) {
  try {
    yield put(load());
    const data = action.data;
    const res = yield call(api.GETAUTH, `user/sendmail/demo?firstname=${data.firstname}&lastname=${data.lastname}&email=${data.email}&phonenumber=${data.phonenumber}&company=${data.company}&totalEmployee=${data.totalEmployee}&department=${data.department}`);
    if(res.error)
      yield put(actions.demoError(res.message));
    else
      yield toast.info('Demo Request sent.', toastConfig);
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* contactUs(action) {
  try {
    yield put(load());
    const res = yield call(api.GETAUTH, `user/sendmail/contactus?name=${action.data.name}&email=${action.data.email}&message=${action.data.message}&toEmail=${action.data.toEmail}`);
    if(res.error)
      yield put(actions.contactError(res.message));

    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    yield console.log(error);
  }
}


export function* gdprform(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'auth/', action.data);
    if(res.error)
      yield put(actions.gdprformError(res.message));
    else
      yield toast.info('Secret code sent.', toastConfig);
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    yield toast.error(error, toastConfig);
  }
}


export function* forgotPassword(action) {
  try {
    yield put(load())
    const res = yield call(api.POST, 'user/forgot-password', action.data)
    if (res.error) yield put(actions.forgotPasswordError(res.message))
    else {
      yield toast.info('Reset link sent.', toastConfig)
    }
    yield put(loaded())
  } catch (error) {
    yield put(loaded())
    Sentry.captureException(error)
    yield toast.error(error, toastConfig)
  }
}

export function* resetPassword(action) {
  try {
    yield put(load())
    const res = yield call(api.POST, 'user/change-password', action.data)
    if (res.error) yield put(actions.forgotPasswordError(res.message))
    else yield toast.info('Password Reset', toastConfig)
    yield put(loaded())
  } catch (error) {
    yield put(loaded())
    Sentry.captureException(error)
    yield toast.error(error, toastConfig)
  }
}

export function* socialLogin(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, action.url);
    console.log('===========socialLogin_res',res);
    if(res.error) {
      if(res.message.length)
        yield toast.error(res.message[0].messages[0].id == 'Auth.form.error.email.taken'? 'Email address already taken': 'Email address already registered');
      else
        yield toast.error(res.message.message);
      yield setTimeout(function() {
        browserHistory.push('/login');
      }, 2000);
    } else {
      yield storeToken(res.jwt);
      yield browserHistory.push('/'+res.user.path);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield browserHistory.push('/login');
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* verifyUser(action) {
  try {
    yield put(load());
    const res = yield call(api.GETAUTH, `auth/verify/${action.code}`);
    if(res.error) {
      if(res.message.length)
        yield toast.error(res.message[0].message);
      else
        yield toast.error(res.message.message);
      yield setTimeout(function() {
        browserHistory.push('/login');
      }, 2000);
    } else {
      yield storeToken(res.jwt);
      yield browserHistory.push('/getting-started');
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield browserHistory.push('/login');
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* validateCoupon(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `coupon/validate/${action.coupon}`);
    if(res.error)
      yield put(actions.couponError('Coupon not valid'));
    else
      yield put(actions.couponSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* getTeam(type) {
  try {
    yield put(load());
    const res = yield call(api.GET, 'user/team?isTeammate='+type.isTeammate);
    // if(res.error)
    //   yield put(actions.couponError('Coupon not valid'));
    // else
    yield put(actions.teamSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* addTeamMember(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'auth/local/invite', action.member);
    if(res.error)
      yield toast.error('This user already has an account', toastConfig);
    else {
      if(action.member && action.member.isTeammate)
        yield toast.info('Invite Sent', toastConfig);
      yield put(actions.teamSuccess(res));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* deleteTeamMember(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `user/${action.memberId}?accType=${action.accType}`);
    if(res.error)
      yield toast.error('Error deleting user');
    else
      yield put(actions.popTeamMember(action.index));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* updateTeamMember(action) {
  try {
    yield put(load());
    const res = yield call(api.PUT, `user/${action.member._id}`, action.member);
    if(res.error)
      yield toast.error('Error updating user');
    else {
      // yield put(actions.teamSuccess(res));
      yield toast('User Updated');
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    yield console.log(error);
  }
}


export function* fetchLogo(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `user/agency/logo?domain=${action.agencydomain}`);
    if(res.error)
      yield toast.error('Error updating user');
    else {
      yield put(actions.fetchLogoSuccess(res));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    yield console.log(error);
  }
}

export function* watchCheckToken() {
  yield takeLatest(actions.CHECK_TOKEN_EXISTS, checkTokenExists);
}

export function* watchFetchUser() {
  yield takeLatest(actions.FETCH, fetchUser);
}

export function* watchFetchRoles() {
  yield takeLatest(actions.FETCHROLES, fetchRoles);
}

export function* watchUpdateUser() {
  yield takeLatest(actions.UPDATE_USER, updateUser);
}

export function* watchAffiliate() {
  yield takeLatest(actions.AFFILIATE, affiliate);
}

export function* watchContactUs() {
  yield takeLatest(actions.CONTACT_US, contactUs);
}

export function* watchDemo() {
  yield takeLatest(actions.DEMO, demo);
}

export function* watchForgotPassword() {
  yield takeLatest(actions.FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
  yield takeLatest(actions.RESET_PASSWORD, resetPassword);
}

export function* watchSocialLogin() {
  yield takeLatest(actions.SOCIAL_LOGIN, socialLogin);
}

export function* watchVerifyUser() {
  yield takeLatest(actions.VERIFY_USER, verifyUser);
}

export function* watchValidateCoupon() {
  yield takeLatest(actions.VALIDATE_COUPON, validateCoupon);
}

export function* watchGetTeam() {
  yield takeLatest(actions.GET_TEAM, getTeam);
}

export function* watchAddTeamMember() {
  yield takeLatest(actions.ADD_TEAM_MEMBER, addTeamMember);
}

export function* watchDeleteTeamMember() {
  yield takeLatest(actions.DELETE_TEAM_MEMBER, deleteTeamMember);
}

export function* watchUpdateTeamMember() {
  yield takeLatest(actions.UPDATE_TEAM_MEMBER, updateTeamMember);
}

export function* watchFetchLogo() {
  yield takeLatest(actions.FETCH_LOGO, fetchLogo);
}

export default function* rootSaga() {
  yield [
    fork(watchCheckToken),
    fork(watchFetchUser),
    fork(watchUpdateUser),
    fork(watchFetchRoles),
    fork(watchAffiliate),
    fork(watchContactUs),
    fork(watchDemo),
    fork(watchForgotPassword),
    fork(watchSocialLogin),
    fork(watchVerifyUser),
    fork(watchValidateCoupon),
    fork(watchResetPassword),
    fork(watchGetTeam),
    fork(watchAddTeamMember),
    fork(watchDeleteTeamMember),
    fork(watchUpdateTeamMember),
    fork(watchFetchLogo)
  ];
}
