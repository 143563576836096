import axios from 'axios'

export const HANDLE_FORM_DATA = "HANDLE_FORM_DATA"


export const getAdvanceSettings = (id) => {
  const authToken = JSON.parse(localStorage.getItem('authToken'))
  const token = authToken ? authToken.token : null

  return async (dispatch) => {
    const res = await axios({
      method: 'GET',
      url: `/rules/${id}`,
      headers: { Authorization: `Bearer ${token}` },
    }).catch((error) => {
      throw error
    })
    dispatch({ type: HANDLE_FORM_DATA, payload: res.data })

    return res
  }
}


export const handleUpdataFormData = (formData, id)=>{
	return(
		async(dispatch) =>{
			const res =await axios({
				method: 'PUT',
				url: `/rules/${id}`,
				data: {
					hideNotification: formData.hideNotification,
					loopNotification: formData.loopNotification,
					notificationFromCountry: formData.country,
					delayNotification: formData.delayNotification,
					closeNotification: formData.closeNotification,
					initialDelay: formData.initialDelay,
					displayTime: formData.displayTime,
					delayBetween: formData.delayBetween,
					displayPosition: formData.displayPosition.value || formData.displayPosition,
					popupAnimationIn: formData.popupAnimationIn.value || formData.popupAnimationIn,
					popupAnimationOut: formData.popupAnimationOut.value || formData.popupAnimationOut,
					animation: formData.animation.value || formData.animation,
					displayNotification: formData.displayNotification,
					popupPositionInMobile: formData.popupPositionInMobile.value || formData.popupPositionInMobile,
					id: formData.id,
					trackingId: formData.trackingId,
					websiteUrl: formData.websiteUrl
				}
			}).catch(error => {throw error})
			dispatch({type: HANDLE_FORM_DATA, payload: res.data.data})
			return(res);
		}
	)
}
