import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { checkTokenExists, fetchLogo } from 'ducks/auth';
import { load, loaded } from 'ducks/loading';
import { CustomLoading, WebsiteHeaderNew } from 'components';
import { ToastContainer, Zoom } from 'react-toastify';
import Popup from 'react-popup';
import * as Sentry from '@sentry/browser';
import { Helmet } from 'react-helmet';
import {
  Favicon
} from 'img';

import 'react-popup/style.css';


// import { checkWhitelable } from 'services/permissions';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false
    };
  }

  componentWillMount() {
    // const host = window.location.host
    // if (checkWhitelable()) {
    //   this.props.fetchLogo(host)
    //   // this.props.fetchLogo('notify.mergebox.io')

    //   return browserHistory.push('/signin')
    // }

    this.props.load()
    document.body.style = 'background-color:white'
    this.checkLogin()
    document.body.style = 'background-color:white'
    let beamer = document.getElementById('beamerSelector')
    if (beamer) beamer.style.display = 'none'
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.location.pathname != this.props.location.pathname)
      this.checkLogin();
    document.body.style = 'background-color:white';
    let beamer = document.getElementById('beamerSelector');
    if(beamer)
      beamer.style.display = 'none';
  }

  componentDidMount() {
    this.props.loaded()
    document.body.style = 'background-color:white'
    // console.log('--------------------------outside loop----------')

    // if (checkWhitelable()) {
    //   console.log('--------------------------running host loog----------')
    //   // this.props.fetchLogo(host);
    //   this.props.fetchLogo('notify.mergebox.io')
    // }
    let beamer = document.getElementById('beamerSelector')
    if (beamer) beamer.style.display = 'none'
  }

  componentDidCatch(error, errorInfo) {
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  checkLogin = () => {
    const { location: { pathname } } = this.props;
    const cookie = localStorage.getItem('authToken');
    const authToken = cookie
      ? JSON.parse(cookie)
      : null;
    if (authToken) {
      if(pathname !== 'checkout') {
        this.props.checkTokenExists(authToken);
        this.setState({loggedIn: true});
      }
    } else {
      if(this.props.location.pathname == '/checkout')
        window.location=`${process.env.REACT_APP_DASHOARD_URL}/login`;
        //browserHistory.push('/login');
      this.setState({loggedIn: false});
    }
  }

  logout = () => {
    localStorage.removeItem('authToken');
    browserHistory.push('/login');
  }

  render() {
    return (
      <div className="website-app">
        <Helmet>
          <title>Influence | Increase Conversion Rate by 17% Using Social Proof!</title>
          <link rel='shortcut icon' href={Favicon} />
        </Helmet>
        <div className="basic-gradient-light" data-smooth-scroll-offset="77">
          <WebsiteHeaderNew loggedIn={this.state.loggedIn} logout={this.logout} />
          <Popup  />
          <CustomLoading isLoading={this.props.loading}/>
          <div style={{background:'#f7f8fb'}}>
            {/*<PageTransition className="content">*/}
            {this.props.children}
            {/*</PageTransition>*/}
          </div>
        </div>
        <ToastContainer hideProgressBar={true} transition={Zoom} position="top-center"/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.getIn(['loading', 'state']),
  user: state.getIn(['auth', 'user'])
});

const mapDispatchToProps = {
  checkTokenExists,
  load,
  loaded,
  fetchLogo
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(App);
