import React, { useEffect, useState, Fragment } from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Breadcrumbs,
  Typography,
  Button,
  LinearProgress,
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router'
import PersonIcon from '@material-ui/icons/Person'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import UpgradeModal from './UpgradeModal'
import PricingDialog1 from '../SettingsPage/PricingDialog1'
// import { checkWhitelable } from '../../services/permissions'
import ConnectionStatus from './ConnectionStatus'
import { Elements } from 'react-stripe-elements'
import UpdateCard from '../SettingsPage/UpdateCard'
import { browserHistory } from 'react-router'
import { getAddedCard } from '../SettingsPage/action'
import { Link } from 'react-router'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import { InfluenceMobile, LogoInfluenceDark } from '../../img'
import { BellIcon } from '../../img'
import { quotaExhausted } from '../../utilities/quotaExhausted'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { useSelector } from 'react-redux'
import moment from 'moment'

const NewHeader = (props) => {
  const {
    user,
    profile,
    logout,
    renderHelp,
    openProfile,
    loading,
    username,
    breadcrumb,
    setBreadCrumbs,
    agencylogo,
  } = props

  const classes = useStyles()
  const meData = useSelector((state) => state.getIn(['auth', 'user'])) || []

  const [openMenu, setOpenMenu] = useState(false)
  const [openMenuDev, setOpenMenuDev] = useState(true)
  const [dialogStatus, setDialogStatus] = useState(true)
  const [addedCard, setAddedCard] = useState({})
  const [updateCardStatus, setUpdateCardStatus] = useState(false)

  useEffect(() => {
    handleCloseUpdateDialog()
  }, [])

  const removeItems = (index, fn, func) => {
    if (func) {
      fn.setActiveState(1, true)
      fn.clearNotification()
    }
    let breadcrumbs = breadcrumb
    breadcrumbs.splice(index + 1)
    setBreadCrumbs(breadcrumbs, true)
  }

  const handleClose = () => {
    setDialogStatus(false)
  }

  const closeHandler = () => {
    setDialogStatus(false)
  }

  const renderBreadCrums = () => {
    let location = window.location.href
    if (location.length > 50 && location.includes('/campaigns/')) {
      return (
        <Fragment>
          <Link style={{ color: '#183247' }} href={'/campaigns'}>
            {'Campaign'}
          </Link>
          <Link style={{ margin: '0 8px', color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem' }}>›</Link>
          <Link style={{ color: '#183247' }}>{'Campaign Settings'}</Link>
        </Fragment>
      )
    } else if (location.length > 50 && location.includes('/Analytics/')) {
      return (
        <Fragment>
          <Link style={{ color: '#183247' }} href={'/campaigns'}>
            {'Campaign'}
          </Link>
          <Link style={{ margin: '0 8px', color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem' }}>›</Link>
          <Link style={{ color: '#183247' }}>{'Campaign Summary'}</Link>
        </Fragment>
      )
    } else {
      return breadcrumb.map((crumb, index) => (
        <Link
          style={{ color: '#183247' }}
          href={crumb.path ? crumb.path : ''}
          key={crumb.name + index}
          onClick={() => removeItems(index, crumb.function, crumb.function ? true : false)}
        >
          {crumb.name}
        </Link>
      ))
    }
  }

  const fetchCard = () => {
    getAddedCard().then((res) => {
      //setIsCardAdded(res.err)
      setAddedCard(res)
    })
  }
  const handleOpenMenu = () => {
    setOpenMenu(!openMenu)
    // setOpenMenu((prev)=>console.log('prev', prev))
  }

  const handleCloseMenu = () => {
    setOpenMenu(false)
  }

  const handleCloseUpdateDialog = () => {
    if (user.status === 'cancel') {
      setUpdateCardStatus(true)
    } else {
      setUpdateCardStatus(false)
    }
  }

  const showTrialProgrees = (meObj) => {
    if (meData.chargebee && meData.chargebee.client_id !== 'cus_1234') {
      return false
    } else {
      return true
    }
  }

  return (
    <Box className={classes.mainHeader}>
      {/* -----Upgrade strip---- */}
      <Box
        className={classes.upgradeStripContainer}
        style={
          quotaExhausted(profile) && window.location.pathname !== '/upgrade-plan'
            ? {}
            : { display: 'none' }
        }
      >
        <Typography>Your account limit has reached. Click here to upgrade.</Typography>
        <Button component={RouterLink} to={'/upgrade-plan'}>
          Upgrade Plan
        </Button>
      </Box>

      {
        // (profile.uniqueVisitorsQoutaLeft < 0  && (profile.plan.name === 'Free Forever Plan' || profile.plan.id === 'price_HJBaYjGF19qCRO')) &&
        <UpgradeModal
          open={quotaExhausted(profile)}
          handleClose={handleClose}
          closeHandler={closeHandler}
          profile={profile}
        />
      }

      <Box className={classes.headerContainer}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {agencylogo ? (
            <Link to="/" className={classes.logoLink}>
              <img
                src={agencylogo && agencylogo.logo ? agencylogo.logo : InfluenceMobile}
                className={classes.logoImage}
                alt=""
                height="60px"
                width="55px"
              />
            </Link>
          ) : (
            <Link
              to={
                user && user.role && (user.role.name == 'campaign' || user.role.name == 'campaignB')
                  ? '/'
                  : user.path
              }
              className={classes.logoLink}
            >
              <img
                src={LogoInfluenceDark}
                className={classes.logoImage}
                alt={user.logoText ? user.logoText : 'influence-img'}
                height="60px"
                width="55px"
              />
            </Link>
          )}
          <Box>
            <Breadcrumbs
              style={{ paddingLeft: '20px' }}
              classes={{ ol: classes.ol }}
              separator="›"
              aria-label="breadcrumb"
            >
              {renderBreadCrums()}
            </Breadcrumbs>
          </Box>
        </Box>

        <ConnectionStatus />
        <Box style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
          {
            // checkWhitelable() || profile.hasOwnProperty("masterBrandUrl") ?
            // 	<></>:
            // 	<RouterLink to="/settings" style={{marginRight:'1rem'}}>
            // 		<SettingsOutlinedIcon  className={classes.settingsIconStyle} />
            // 	</RouterLink>
          }

          {showTrialProgrees(meData) &&
            meData?.payments?.[0]?.trial_end &&
            meData?.payments?.[0]?.trial_end > 1614563647 &&
            Date.now() / 1000 <= meData?.payments?.[0]?.trial_end && (
              <Box className={classes.trialsLeftContainer}>
                <LinearProgress
                  variant="determinate"
                  value={
                    (((meData?.payments?.[0]?.trial_end - Math.round(Date.now() / 1000)) /
                      (60 * 60 * 24)) *
                      100) /
                    14
                  }
                />
                <Typography>
                  {moment
                    .duration(
                      meData?.payments?.[0]?.trial_end +
                        24 * 60 * 60 -
                        Math.round(Date.now() / 1000),
                      'seconds'
                    )
                    .days()}{' '}
                  days trial left
                </Typography>
                <Button
                  startIcon={<TrendingUpIcon fontSize="small" style={{ fontSize: 14 }} />}
                  component={Link}
                  style={{ marginLeft: '18px' }}
                  to="/upgrade-plan"
                  variant="contained"
                >
                  Upgrade
                </Button>
              </Box>
            )}

          <Button
            startIcon={<HelpOutlineIcon style={{ fontSize: 14 }} fontSize="small" />}
            variant="outlined"
            component="a"
            href="https://support.useinfluence.co/"
            target="_blank"
          >
            Help
          </Button>
          <ClickAwayListener onClickAway={handleCloseMenu}>
            <Box
              className={openMenu ? classes.rightSideActive : classes.rightSide}
              onClick={handleOpenMenu}
            >
              <div style={{ padding: '0px' }} className={classes.titleLetter}>
                {profile.image ? (
                  <img className={classes.titleImage} src={profile.image}></img>
                ) : (
                  <span>{username ? username.charAt(0).toUpperCase() : '?'}</span>
                )}
                {/*username ? username.charAt(0).toUpperCase() + username.slice(1): 'Anonymous'*/}
              </div>
              <Box
                className={openMenu ? classes.menuStyle : classes.hideMenuStyle}
                onMouseLeave={handleCloseMenu}
              >
                <span className={classes.menuItem} onClick={openProfile}>
                  <Typography
                    component={RouterLink}
                    variant="h5"
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }} /*to="/profile"*/
                  >
                    <PersonIcon style={{ marginRight: '5px', fontSize: '1rem' }} /> Your Profile
                  </Typography>
                </span>
                {/* {checkWhitelable() || profile.hasOwnProperty('masterBrandUrl') ? null : (
                  <span className={classes.menuItem} onClick={openProfile}>
                    <Typography
                      component={RouterLink}
                      variant="h5"
                      style={{
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <PersonIcon style={{ marginRight: '5px', fontSize: '1rem' }} /> Your Profile
                    </Typography>
                  </span>
                )} */}

                <span onClick={logout} className={classes.menuItem}>
                  <Typography
                    component={RouterLink}
                    variant="h5"
                    style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}
                  >
                    <PowerSettingsNewIcon style={{ marginRight: '5px', fontSize: '1rem' }} /> Logout
                  </Typography>
                </span>
              </Box>
            </Box>
          </ClickAwayListener>
        </Box>
        {user.status === 'cancel' && (
          <Elements>
            <UpdateCard
              fetchCard={fetchCard}
              addingNewCard={false}
              addedCard={addedCard}
              open={updateCardStatus}
              handleClose={handleCloseUpdateDialog}
            />
          </Elements>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    mainHeader: {
      zIndex: theme.zIndex.appBar,
    },
    headerContainer: {
      height: '60px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 2rem 0 0.5rem',
      position: 'fixed',
      zIndex: theme.zIndex.appBar,
      background: '#fff',
      boxShadow: '0px 1px 1px 0px #e5e5e5',
      left: 0,
      paddingLeft: '0',
    },
    rightSide: {
      position: 'relative',
      padding: '5px',
      borderRadius: '5px',
      cursor: 'pointer',
      '&:hover': {
        // backgroundColor: '#f2f2f2',
        cursor: 'pointer',
      },
    },
    rightSideActive: {
      position: 'relative',
      padding: '5px',
      borderRadius: '5px',
      // backgroundColor: '#f2f2f2',
      cursor: 'pointer',
    },
    menuStyle: {
      width: '150px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      justifyContent: 'center',
      position: 'absolute',
      transition: '0.2s',
      borderRadius: '2px',
      border: '1px solid #e3eaef',
      boxShadow: '0 3px 12px rgba(0,0,0,0.05)',
      right: '-17px',
    },
    ol: {
      marginBottom: '0 !important',
    },
    hideMenuStyle: {
      display: 'none',
      transition: '0.2s',
    },
    menuItem: {
      padding: '12px',
      cursor: 'pointer',
      color: '#000',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: '#f9f9f9',
      },
    },
    titleLetter: {
      backgroundColor: '#fba62d',
      fontSize: '13px',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      textAlign: 'center',
      padding: '0',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleImage: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      textAlign: 'center',
      color: '#fff',
      // marginRight: '7px'
    },
    settingsIconStyle: {
      fontSize: '40px',
      color: 'gray',
      cursor: 'pointer',
      padding: '8px',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#f7f8fb',
      },
    },
    logoImage: {
      marginTop: '2px',
      borderRadius: '2px',
      maxWidth: '60px',
      width: 'auto',
      height: '45px',
    },
    logoLink: {
      width: '55px',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    upgradeStripContainer: {
      height: theme.custom.upgradeStrip.height,
      backgroundColor: '#0077ff',
      marginLeft: `-${theme.custom.sideBar.width + 5}px`,
      textAlign: 'center',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&>p': {
        margin: '0',
        textAlign: 'center',
        fontSize: '14px',
        backgroundColor: '#07f ',
        color: '#fff',
        padding: '11px',
        fontWeight: '400',
      },
      '&> a': {
        padding: '5px',
        backgroundColor: '#00d200',
        color: '#fff',
        borderRadius: '3px',
        marginLeft: '5px',
      },
    },
    trialsLeftContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      '&>div': {
        width: 100,
        margin: theme.spacing(0, 1),
      },
      '&>p': {
        fontSize: 14,
      },
    },
  })
)

export default NewHeader
