import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';



const AnalyticsGraph = (props) => {

    const {selectedDuration} = props

    const [newDataState, setNewDataState] = useState({labels:[], datasets: []})

    // const infoNew = useSelector(state => (state.getIn(['campaign', 'campaignInfoNew'])));
  const infoNew = useSelector(state => (state.getIn(['AnalyticsData', 'campaignAnalyticsData'])));


    const visitors =infoNew && Object.keys(infoNew.currentTimeWindowData.totalsByDay?.uniqueVisitors ?? {});
    const clicks = infoNew && Object.keys(infoNew.currentTimeWindowData.totalsByDay?.clicks ?? {})
    const signups = infoNew && Object.keys(infoNew.currentTimeWindowData.totalsByDay?.signups ?? {})
    //const reviews = infoNew && Object.keys(infoNew.currentTimeWindowData.totalsByDay.reviews);
    const pageView =
      infoNew && Object.keys(infoNew.currentTimeWindowData.totalsByDay?.pageView ?? {})
    const hover = infoNew && Object.keys(infoNew.currentTimeWindowData.totalsByDay?.hover ?? {})

    const checkMonth = (num) => {
      switch(num){
        case 1:
          return 'Jan';
        case 2:
          return 'Feb';
        case 3:
          return 'Mar';
        case 4:
          return 'Apr';
        case 5:
          return 'May';
        case 6:
          return 'June';
        case 7:
          return 'July';
        case 8:
          return 'Aug';
        case 9:
          return 'Sept';
        case 10:
          return 'Oct';
        case 11:
          return 'Nov';
        case 12:
          return 'Dec';
        default:
          return '';
      }
    }

    const findDate = (numDays) => {
      var days = numDays; // Days you want to subtract
      var date = new Date();
      var last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
      var day =last.getDate();
      var month=last.getMonth()+1;
      // var year=last.getFullYear();

      return(day + '' + checkMonth(month));
    }


    const dataCreater = (reqNum) =>{
      let chartdata = {
        labels: [],
        datasets: [
          {
            label: 'Visitors',
            pointHoverRadius: 30,
            pointRadius: 5,
            // pointHitRadius: 10,
            hoverBackgroundColor: "rgba(69, 250, 24, 0.2)",
            backgroundColor: "rgba(69, 250, 24, 0.2)",
            hoverBorderColor: "#246201",
            data: []
          },
          {
            label: 'Clicks',
            pointHoverRadius: 30,
            pointRadius: 5,
            // pointHitRadius: 10,
            hoverBackgroundColor: "rgba(89, 193, 202,0.3)",
            backgroundColor: "rgba(89, 193, 202,0.3)",
            hoverBorderColor: "#59c1ca",
            data: []
          },
          {
            label: 'Signups',
            pointHoverRadius: 30,
            pointRadius: 5,
            // pointHitRadius: 10,
            hoverBackgroundColor: "rgba(192, 66, 190,0.3)",
            backgroundColor: "rgba(192, 66, 190,0.3)",
            hoverBorderColor: "#c042be",
            data: []
          },
          // {
          //   label: 'Reviews',
          //   pointHoverRadius: 30,
          //   pointRadius: 5,
          //   // pointHitRadius: 10,
          //   hoverBackgroundColor: "rgba(2, 134, 144,0.3)",
          //   backgroundColor: "rgba(2, 134, 144,0.3)",
          //   hoverBorderColor: "#028690",
          //   data: []
          // },
          {
            label: 'Views',
            pointHoverRadius: 30,
            pointRadius: 5,
            // pointHitRadius: 10,
            hoverBackgroundColor: "rgba(216, 87, 42,0.3)",
            backgroundColor: "rgba(216, 87, 42,0.3)",
            hoverBorderColor: "rgb(216, 87, 42)",
            data: []
          },
          {
            label: 'Hover',
            pointHoverRadius: 30,
            pointRadius: 5,
            // pointHitRadius: 10,
            hoverBackgroundColor: "rgba(223, 255, 15,0.3)",
            backgroundColor: "rgba(223, 255, 15,0.3)",
            hoverBorderColor: "rgb(223, 255, 15)",
            data: []
          }
        ]
      };
      // for(let i=reqNum; i--; i<=0){

      //   graphData.push({
      //     name: findDate(i),
      //     visitors: infoNew.currentTimeWindowData.totalsByDay.uniqueVisitors[visitors[i]] !== undefined ? infoNew.currentTimeWindowData.totalsByDay.uniqueVisitors[visitors[i]] : 0,
      //     clicks: infoNew.currentTimeWindowData.totalsByDay.clicks[clicks[i]] !== undefined ? infoNew.currentTimeWindowData.totalsByDay.clicks[clicks[i]] : 0,
      //     signups: infoNew.currentTimeWindowData.totalsByDay.signups[signups[i]] !== undefined ? infoNew.currentTimeWindowData.totalsByDay.signups[signups[i]] : 0,
      //     reviews: infoNew.currentTimeWindowData.totalsByDay.reviews[reviews[i]] !== undefined ? infoNew.currentTimeWindowData.totalsByDay.reviews[reviews[i]] : 0
      //   })

      // }

      for (var i = reqNum; i--; i <= 0) {
        chartdata.labels.push(findDate(i))

        if (infoNew.currentTimeWindowData.totalsByDay) {
          chartdata.datasets[0].data.push(
            infoNew.currentTimeWindowData.totalsByDay.uniqueVisitors[visitors[i]] !== undefined
              ? infoNew.currentTimeWindowData.totalsByDay.uniqueVisitors[visitors[i]]
              : 0
          )

        chartdata.datasets[1].data.push(
          infoNew.currentTimeWindowData.totalsByDay.clicks[clicks[i]] !== undefined
            ? infoNew.currentTimeWindowData.totalsByDay.clicks[clicks[i]]
            : 0
        )

        chartdata.datasets[2].data.push(
          infoNew.currentTimeWindowData.totalsByDay.signups[signups[i]] !== undefined
            ? infoNew.currentTimeWindowData.totalsByDay.signups[signups[i]]
            : 0
        )

        // chartdata.datasets[3].data.push(
        //   infoNew.currentTimeWindowData.totalsByDay.reviews[reviews[i]] !== undefined ? infoNew.currentTimeWindowData.totalsByDay.reviews[reviews[i]] : 0
        // )

        chartdata.datasets[3].data.push(
          infoNew.currentTimeWindowData.totalsByDay.pageView[pageView[i]] !== undefined
            ? infoNew.currentTimeWindowData.totalsByDay.pageView[pageView[i]]
            : 0
        )

        chartdata.datasets[4].data.push(
          infoNew.currentTimeWindowData.totalsByDay.hover[hover[i]] !== undefined
            ? infoNew.currentTimeWindowData.totalsByDay.hover[hover[i]]
            : 0
        )
        }
      }

      setNewDataState(chartdata)

    }


    useEffect(()=>{
      if(infoNew){
        dataCreater(selectedDuration)
      }
      console.log('infoNew--', infoNew)
    },[selectedDuration, infoNew])



    // const data = {
    //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //   datasets: [
    //     {
    //       label: 'My First dataset',
    //       fill: false,
    //       lineTension: 0.4,
    //       backgroundColor: 'rgba(75,192,192,0.4)',
    //       borderColor: 'rgba(75,192,192,1)',
    //       borderCapStyle: 'butt',
    //       borderDash: [],
    //       borderDashOffset: 0.0,
    //       borderJoinStyle: 'miter',
    //       pointBorderColor: 'rgba(75,192,192,1)',
    //       pointBackgroundColor: '#fff',
    //       pointBorderWidth: 1,
    //       pointHoverRadius: 30,
    //       pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //       pointHoverBorderColor: 'rgba(220,220,220,1)',
    //       // pointHoverBorderWidth: 10,
    //       pointRadius: 6,
    //       // pointHitRadius: 10,
    //       data: [65, 59, 80, 81, 56, 55, 40]
    //     }
    //   ]
    // };

    return (
      <div>
        <Box style={{marginTop: '20px'}}>
          <Line
            data={newDataState}
            height = {80}
            options={{
              scales:{
                yAxes:[{
                  gridLines:{
                    display:false
                  },
                  ticks:{
                    beginAtZero:true,
                    display:true
                  }
                }]
              }
            }}
          />
        </Box>
      </div>

    );
}


export default AnalyticsGraph;