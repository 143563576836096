import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, createStyles, withStyles, Button } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router'
import Paper from '@material-ui/core/Paper'
import LinearProgress from '@material-ui/core/LinearProgress'
import DoneIcon from '@material-ui/icons/Done'
import { Influence, LogoInfluenceNew } from '../../img'
import { likeStarted } from '../../img'
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { updateUser } from '../../ducks/auth'
import { browserHistory } from 'react-router'
import { getCampaignInfo } from '../CampaignList/action'
import CreateCampDialogForm from '../Sidebar/CreateCampDialogForm'
// import { checkWhitelable } from '../../services/permissions'
import VideoDemoModal from './VideoDemoModal'

const GettingStarted1 = () => {
  // const showCalendly = () => {
  //   const calendlyStatus = localStorage.getItem('calendly')
  //   if (calendlyStatus) {
  //     window.Calendly.initPopupWidget({ url: 'https://calendly.com/kirti-1/one-on-one' })
  //   }
  // }

  // const fireCalendlyPopup = () => {
  //   window.Calendly.initPopupWidget({ url: 'https://calendly.com/kirti-1/one-on-one' })
  // }

  useEffect(() => {
    // showCalendly()
    return () => {
      localStorage.removeItem('calendly')
    }
  }, [])

  const classes = usestyles()
  const [progressValue, setprogressValue] = useState(32)
  const [profileCompleted, setProfileCompleted] = useState()
  const [checkValidPixel, setCheckValidPixel] = useState(false)
  const [openVideoDemoModal, setOpenVideoModal] = useState(false)

  // get data from reducer.
  const agencyLogo = useSelector((state) => state.getIn(['auth', 'agencylogo']))
  const userData = useSelector((state) => state.getIn(['auth', 'user'])) || []
  const dashboardData = useSelector((state) => state.getIn(['campaign', 'campaignInfoNew'])) || []
  const campaignInfo = useSelector((state) => state.getIn(['CampaignInfo', 'campaignInfo'])) || []
  const profile = useSelector((state) => state.getIn(['profile', 'profile'])) || []
  const [dialogStatus, setDialogStatus] = useState(false)

  // const auth = useSelector(state => (state.getIn(['auth', 'user']))) || [];
  const dispatch = useDispatch()

  const getPixelStatus = async () => {
    const authToken = JSON.parse(localStorage.getItem('authToken'))
    const token = authToken ? authToken.token : null

    const res = await axios({
      method: 'GET',
      url: '/campaign/pixelDetail',
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => {
      throw err
    })

    setCheckValidPixel(res.data.length !== 0 ? true : false)
    if (res.data.length > 0) {
      setprogressValue((prevValue) => prevValue + 34)
    }
  }

  useEffect(() => {
    getPixelStatus()
  }, [])

  useEffect(() => {
    if (progressValue > 100) {
      setprogressValue(100)
    }
    if (progressValue === 100) {
      let userUpdate = {}
      userUpdate['path'] = '/campaigns'
      userUpdate['id'] = userData._id
      dispatch(updateUser(userUpdate))
    }
  }, [progressValue])

  const BorderLinearProgress = withStyles({
    root: {
      height: 16,
      borderRadius: '3px',
      backgroundColor: '#eff4f9',
    },
    bar: {
      borderRadius: 3,
      backgroundColor: '#2c66c3',
    },
  })(LinearProgress)

  // check whether the first campaign is launch or not.
  useEffect(() => {
    if (campaignInfo.length === 0) {
      dispatch(getCampaignInfo())
    }
    if (campaignInfo.length !== 0) {
      // added 20% more to progress bar.
      setprogressValue((prevValue) => prevValue + 34)
    }
  }, [campaignInfo.length])

  // //check whether profile is completed or not.
  //   useEffect(() => {
  //     //it will return if following condition is fullfilled.
  //     if(profile.firstName === undefined || userData.websiteUrl === undefined  || profile.phoneNumber === undefined) return;
  //     if(profile.firstName === null  || userData.websiteUrl === null  || profile.phoneNumber === null) return;
  //     // added 20% more to progress bar.
  //     setprogressValue(prevValue => prevValue + 25);
  //     setProfileCompleted(10);
  //   },[profile]);

  //   check whether the pixel is installed or not.
  useEffect(() => {
    if (dashboardData.length !== 0) {
      if (dashboardData.currentTimeWindowData.total.uniqueVisitors !== 0) {
        // added 20% more to the pixel value.
        setprogressValue((prevValue) => prevValue + 34)
        //console.log("pixel",progressValue)
        setCheckValidPixel(true)
      }
    }
  }, [dashboardData.length])

  //  browser back to dashboard when click on skip to dashboard.
  // const handleCampaignCheck = () =>{
  //    return browserHistory.push('/new');
  // }

  const handleDialogOpen = () => {
    setDialogStatus(true)
  }

  const handleClose = () => {
    setDialogStatus(false)
  }

  const closeHandler = () => {
    setDialogStatus(false)
  }

  const handleVideoDemoModal = () => {
    setOpenVideoModal((prev) => !prev)
  }

  return (
    <Box style={{ position: 'relative' }}>
      {!checkValidPixel && (
        <Box className={classes.demoStripContainer}>
          <Typography>Click here to learn how to setup Influence</Typography>
          <Button variant="outlined" onClick={handleVideoDemoModal}>
            View Demo
          </Button>
        </Box>
      )}
      <VideoDemoModal onClose={handleVideoDemoModal} open={openVideoDemoModal} />
      <Container
        style={{
          width: '60%',
          minWidth: '500px',
          maxWidth: '730px',
          paddingTop: checkValidPixel ? '0' : '40px',
        }}
      >
        <Box component={Paper} className={classes.box} elevation={0}>
          <div className={classes.TypoImg}>
            <img
              // src={
              //   checkWhitelable()
              //     ? agencyLogo
              //       ? agencyLogo.logo
              //       : LogoInfluenceNew
              //     : LogoInfluenceNew
              // }
              src={LogoInfluenceNew}
              className={classes.img}
            />
            <Typography className={classes.Typography}>
              Welcome, {userData.userName || userData.username}. Let's get started!
            </Typography>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 25px 21px 31px' }}>
            <div style={{ display: 'flex', paddingBottom: '4px', justifyContent: 'space-between' }}>
              <Typography fontSize={10} className={classes.progress}>
                Progress...
              </Typography>
              <p className={classes.progress}>{progressValue}%</p>
            </div>
            <BorderLinearProgress
              className={classes.margin}
              variant="determinate"
              color="primary"
              value={progressValue}
            />
          </div>
        </Box>

        <Box className={classes.typoBox2} component={Paper} elevation={0}>
          <div className={classes.mainDiv}>
            <div className={classes.flexBox}>
              <div style={{ marginRight: '11px' }} className={classes.CheckCircle}>
                <CheckCircleSharpIcon style={{ fontSize: '30px', color: '#00B200' }} />
              </div>
              <div className={classes.subDiv1}>
                <Typography className={classes.typo1}>
                  Do these tasks to start using your account.
                </Typography>
                <Typography
                  style={{ margin: '0px', fontSize: '12px', color: '#5c6f81', fontFamily: '' }}
                >
                  Takes about 20 minutes.
                </Typography>
              </div>
            </div>
            <div className={classes.subDiv2}>
              <img src={likeStarted} className={classes.img1} />
            </div>
          </div>

          <div className={classes.mainDiv2}>
            <div className={classes.CheckCircle}>
              <DoneIcon fontSize="small" color="primary" />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <Typography className={classes.typoTick}>Account created successfully.</Typography>
            </div>
          </div>

          <div className={classes.mainDiv3}>
            <div className={classes.flexBox}>
              <div className={classes.CheckCircle}>
                {/* check whether the pixel is installed and change to primary when pixel is installed. */}

                {checkValidPixel === '' ||
                checkValidPixel === undefined ||
                checkValidPixel === false ? (
                  <DoneIcon fontSize="small" />
                ) : (
                  <DoneIcon fontSize="small" color="primary" />
                )}
              </div>
              <div className={classes.subDiv1}>
                <Typography className={classes.typo}>Install Your Pixel!</Typography>
                <Typography style={{ fontSize: '14px', color: '#5c6f81' }}>
                  After installing the pixel create a campaign to verify it.
                </Typography>
              </div>
            </div>
            {/* button shows when pixel is installed and doesn't show when installation is completed. */}
            {checkValidPixel === false ? (
              <div className={classes.connect}>
                <Button
                  style={{ fontSize: '11px', width: '113px' }}
                  variant="contained"
                  component={Link}
                  to={'/installPixelPage'}
                >
                  Connect
                </Button>
              </div>
            ) : null}
          </div>

          <div className={classes.mainDiv3}>
            <div className={classes.flexBox}>
              <div className={classes.CheckCircle}>
                {/* check whether the first campaign is launch or not */}
                {campaignInfo.length === 0 ? (
                  <DoneIcon fontSize="small" />
                ) : (
                  <DoneIcon fontSize="small" color="primary" />
                )}
              </div>
              <div className={classes.subDiv1}>
                <Typography className={classes.typo}>Launch your first Campaign</Typography>
                <Typography style={{ margin: '0px', fontSize: '14px', color: '#5c6f81' }}>
                  Start converting more on your website.
                </Typography>
              </div>
            </div>
            {/* button shows when first campaign is slaunch and doesn't show when campaign is launch. */}
            {campaignInfo.length === 0 ? (
              <div className={classes.connect}>
                <Button
                  style={{ fontSize: '11px', width: '113px' }}
                  onClick={handleDialogOpen}
                  variant="contained"
                >
                  Create
                </Button>
              </div>
            ) : null}
          </div>
        </Box>
      </Container>
      <CreateCampDialogForm
        open={dialogStatus}
        handleClose={handleClose}
        closeHandler={closeHandler}
      />
    </Box>
  )
}

const usestyles = makeStyles((theme) =>
  createStyles({
    Typography: {
      fontSize: '16px',
      color: '#5c6f81',
      fontWeight: '500',
      margin: '0%',
      paddingTop: '15px',
    },
    mainDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '11px 28px 11px 32px',
      borderBottom: '0.5px solid  #e8e8e8',
    },
    box: {
      borderRadius: '10px',
      border: '1px solid rgba(177, 177, 177, 0.3)',
      boxShadow: 'rgba(198, 198, 198, 0.32) 0px -1px 2px 0px',
    },
    flexBox: {
      display: 'flex',
    },
    subDiv1: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '10px',
      justifyContent: 'center',
    },
    subDiv2: {
      padding: '4% 5% 3% 0%',
    },
    mainDiv2: {
      display: 'flex',
      marginTop: '4px',
      padding: '0px 28px 0px 35px',
      borderBottom: '0.5px solid  #e8e8e8',
    },
    mainDiv3: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '0.5px solid  #e8e8e8',
      padding: '11px 28px 11px 35px',
    },
    CheckCircle: {
      display: 'flex',
      alignItems: 'center',
      color: '#e4e8ef',
      marginRight: '21px',
    },
    typo1: {
      color: '#5c6f81',
      fontWeight: '500',
      marginBottom: '8px',
    },
    typo: {
      fontSize: '14px',
      color: '#5c6f81',
      fontWeight: '500',
      margin: '4px 0px',
      // fontFamily: ' !important'
    },
    connect: {
      display: 'flex',
      alignItems: 'center',
    },
    progress: {
      marginBottom: '0px',
      fontWeight: '400',
      color: '#5c6f81 ',
      fontSize: '12px',
      marginLeft: '2px',
    },
    typoTick: {
      fontWeight: '500',
      color: '#5c6f81  !important',
      fontSize: '14px !important',
      margin: '1rem 0',
    },
    img: {
      maxWidth: '150px',
      width: 'auto',
      height: '37px !important',
      cursor: 'default',
    },
    TypoImg: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '37px',
      flexDirection: 'column',
      paddingTop: '28px',
    },
    img1: {
      width: 'auto',
      height: '56px !important',
    },
    typoBox2: {
      marginTop: theme.spacing(3),
      borderRadius: '10px',
      border: '1px solid rgba(177, 177, 177, 0.3)',
      boxShadow: 'rgba(198, 198, 198, 0.32) 0px -1px 2px 0px',
      // color: 'rgb(92, 111, 129)',
      // fontFamily: ''
    },
    typoLast: {
      color: '#5c6f81 !important',
      marginTop: '9px !important',
      fontFamily: ' !important',
    },
    link: {
      display: 'flex',
      justifyContent: 'center',
      fontFamily: ' !important',
    },
    demoStripContainer: {
      position: 'absolute',
      top: -24,
      left: -35,
      // width:'calc(100% + 30px)',
      width: `calc(100vw - ${
        parseInt(theme.custom.sideDrawer.width.substring(0, 3)) + theme.custom.sideBar.width
      }px)`,
      height: 40,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      justifyContent: 'center',
      minWidth:'calc(100vh - (55px + 150px) )'
    },
  })
)

export default GettingStarted1
