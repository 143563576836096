export const GET_GOAL_DATA = 'GET_GOAL_DATA';
export const PUT_GOAL_DATA = 'PUT_GOAL_DATA';
export const DELETE_GOAL_DATA = 'DELETE_GOAL_DATA';
export const GET_GOAL_FETCHING = 'GET_GOAL_FETCHING';
export const GOAL_SCHEDULAR = 'GOAL_SCHEDULAR'
import axios from 'axios';
import mixpanel from 'mixpanel-browser'
export const CREATE_GOAL = 'CREATE_GOAL';

// get request in goal section.
export const getGoalData = (trackingId,setLoading) => {
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    // loader is shown when request is made.
    setLoading(true);
return(
    async(dispatch) =>{
        const response = await axios({
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
          url: `/goal?trackingId=${trackingId}`,
        }).catch((error) => {
          throw error
        })
        dispatch({ type: GET_GOAL_DATA, payload: response.data })
        dispatch({type:GET_GOAL_FETCHING,payload:response.data});
        // loader is false when request is fullfilled
        setLoading(false);
    }
)
}
// put request is called.
export const putGoalData = (data) =>{
    return(
        async(dispatch) =>{
            const response = await axios({
                method:'PUT',
                url:`/goal/${data.id}`,
                data:data
            }).catch(error => {throw error});
            dispatch({type:PUT_GOAL_DATA,payload:response.data});
        }
    )
}

// delete request is called.
export const deleteGoalData = (id) =>{
    return(
        async(dispatch) =>{
            const response = await axios({
                method:'DELETE',
                url:`/goal/${id}`
            }).catch(error => {throw error});
            dispatch({type:DELETE_GOAL_DATA,payload:response.data});
            return(response);
        }
    )
}


// Goal schedular caller
export const goalSchedular = (trackingId) =>{
    return(
        async(dispatch) =>{
            const res = await axios({
              method: 'GET',
              url: `/goal/schedularForGoal?trackingId=${trackingId}&dataDuration=${'7'}`,
            }).catch((err) => {
              throw err
            })
            dispatch({type: GOAL_SCHEDULAR , payload:res.data})
        }
    )
}

export const createGoal = (data) => {
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    return(
        async(dispatch) => {
            const res = await axios({
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                url: '/goal',
                data: data
            }).catch(err => {throw err})
            dispatch({type: CREATE_GOAL, payload: res.data})
            mixpanel.track('Goal Creation', {
              goalName: res.data.name,
              goalid: res.data._id,
              pageUrl: res.data.url,
              description: res.data.description,
              category: res.data.category.label,
              campaign: res.data.campaign.campaignName,
            })
        }
    );
}