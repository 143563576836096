import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PropTypes from 'prop-types'
// import { checkWhitelable } from '../../services/permissions';



const InfluenceHelpIcon  = (props) => {
    const {fontSize="small", href, ...rest} = props
    const classes = useStyles()
    return (
      <a tabIndex={-1} href={href} target="_blank" {...rest} style={{ verticalAlign: 'middle' }}>
        <HelpOutlineOutlinedIcon
          classes={{
            root: classes.iconRoot,
            // fontSizeSmall:classes.fontsizeSmall,
            // fontSizeLarge:classes.fontSizeLarge
          }}
          // fontSize={fontSize}
          className={classes[fontSize]}
          // style={checkWhitelable()?{display:'none'}:{}}
        />
      </a>
    )
}

const useStyles = makeStyles(theme =>
    createStyles({
        iconRoot:{
            color:'#479fffe0',
            cursor:'pointer',
            margin:'0 3px'
        },
        fontsizeSmall:{
            fontSize:'0.9rem'
        },
        fontSizeLarge:{
            fontSize:'1.15rem'
        },
        fontSizeDefault:{
            fontSize:'1rem'
        },
        small:{
            fontSize: '1rem'
        },
        large:{
            fontSize:'1.15rem'
        },
        tiny:{
            fontSize:'0.8rem'
        }
    })
)

InfluenceHelpIcon.propTypes ={
    fontSize: PropTypes.oneOf["small","large","default","inherit", "tiny"],
    href: PropTypes.string,
}


export default InfluenceHelpIcon