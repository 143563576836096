import React, { useState, useEffect } from 'react';
import { Box, makeStyles, createStyles, Paper, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfileSettings } from './action';
import TextInput from '../../Globals/GlobalComponents/TextInput';
import PauseDialog from './PauseDialog';
import axios from 'axios';
import {submitAccountOtp} from '../../ducks/profile';
import { browserHistory } from 'react-router';
import DeleteDialog from './DeleteDialog';
import LoadingButton from '../../Globals/GlobalComponents/LoadingButton'

const ProfileSettingsPage  = () => {
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;

    const classes = useStyles();
    const dispatch = useDispatch();
    const profile = useSelector(state => (state.getIn(['profile', 'profile']))) || []
    const user = useSelector(state => (state.getIn(['auth', 'user']))) || []

    const [loading, setLoading] = useState(false);
    const [pauseDialog, setPauseDialog] = useState(false);
    const [pauseLoading, setPauseLoading] = useState(false);
    const [enterOtp, setEnterOtp] = useState(false);
    const [otp, setOtp] = useState('')
    const [deleteOtp, setDeleteOtp] = useState('')
    const [pauseConfirmLoading, setPauseConfirmloading] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [profileData, setProfileData] = useState({
      user: profile.user,
      userName: user.userName,
      firstName: profile.firstName,
      lastName: profile.lastName,
      websiteUrl: user.websiteUrl || profile.websiteUrl || '',
      phoneNumber: profile.phoneNumber,
      weeklyReportSubscribe: profile.weeklyReportSubscribe,
      monthlyReportSubscribe: profile.monthlyReportSubscribe,
    })

    const handleUpdate = () => {
      dispatch(updateProfileSettings(profile._id, profileData, setLoading))
    }

    const closePauseDialog = () => {
      setPauseDialog(false)
    }

    const closeDeleteDialog = () => {
      setDeleteDialog(false)
    }

    const handlePauseSubmit = async () => {
      setPauseDialog(true)
      setPauseLoading(true)
      const res = await axios({
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
        url: `/profile/otp/${user.status}`,
      }).catch((err) => {
        setPauseLoading(false)
        throw err
      })
      if (res.data.msg === 'Mail sent') {
        setEnterOtp(true)
      }
      setPauseLoading(false)
    }

    const handlePauseConfirm = async () => {
      setPauseConfirmloading(true)
      dispatch(submitAccountOtp({ otpCode: otp, type: user.status }))
      setPauseConfirmloading(false)
    }

    const handleDeleteSubmit = async () => {
      setPauseLoading(true)
      const res = await axios({
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
        url: `/profile/otp/delete`,
      }).catch((err) => {
        setPauseLoading(false)
        throw err
      })
      if (res.data.msg === 'Mail sent') {
        setEnterOtp(true)
      }
      setPauseLoading(false)
    }

    const handleDeleteConfirm = async () => {
      setPauseConfirmloading(true)
      await axios({
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        url: '/profile/otp/submit',
        data: {
          otpCode: deleteOtp,
          type: 'delete',
        },
      }).catch((err) => {
        setPauseConfirmloading(false)
        throw err
      })
      browserHistory.push('/')
    }

    const handleFormDataChange = (e) => {
      setProfileData({ ...profileData, [e.target.name]: e.target.value })
    }

    const handleFormCheckbox = (e) => {
      setProfileData({ ...profileData, [e.target.name]: e.target.checked })
    }



    return (
      <Box
        component={Paper}
        padding="3% 6%"
        elevation={0}
        style={{
          boxShadow: '0px -1px 2px 0px rgba(198, 198, 198, 0.32)',
          border: '1px solid rgba(177, 177, 177, 0.3)',
        }}
      >
        {/* <SettingsPageSeketon/> */}
        <Box className={classes.fieldsWrapper}>
          <Box>
            <Box>
              <Typography>Username</Typography>
              <TextInput
                className={classes.inputBox}
                value={profileData.userName}
                onChange={handleFormDataChange}
                disabled={loading}
                name={'userName'}
              />
            </Box>
            <Box>
              <Typography>First Name</Typography>
              <TextInput
                className={classes.inputBox}
                value={profileData.firstName}
                onChange={handleFormDataChange}
                disabled={loading}
                name={'firstName'}
              />
            </Box>
            <Box>
              <Typography>Phone</Typography>
              <TextInput
                className={classes.inputBox}
                value={profileData.phoneNumber}
                onChange={handleFormDataChange}
                disabled={loading}
                name={'phoneNumber'}
              />
            </Box>
          </Box>

          <Box>
            <Box>
              <Typography>Email</Typography>
              <TextInput className={classes.inputBox} disabled value={user.email} />
            </Box>
            <Box>
              <Typography>Last Name</Typography>
              <TextInput
                className={classes.inputBox}
                value={profileData.lastName}
                onChange={handleFormDataChange}
                disabled={loading}
                name={'lastName'}
              />
            </Box>
            <Box>
              <Typography>Website URL</Typography>
              <TextInput
                className={classes.inputBox}
                value={profileData.websiteUrl}
                onChange={handleFormDataChange}
                disabled={loading}
                name={'websiteUrl'}
              />
            </Box>
            {pauseDialog && (
              <PauseDialog
                pauseDialog={pauseDialog}
                closePauseDialog={closePauseDialog}
                handlePauseSubmit={handlePauseSubmit}
                loading={pauseLoading}
                enterOtp={enterOtp}
                otp={otp}
                setOtp={setOtp}
                handlePauseConfirm={handlePauseConfirm}
                pauseConfirmLoading={pauseConfirmLoading}
              />
            )}
            {deleteDialog && (
              <DeleteDialog
                deleteDialog={deleteDialog}
                closeDeleteDialog={closeDeleteDialog}
                handleDeleteSubmit={handleDeleteSubmit}
                loading={pauseLoading}
                enterOtp={enterOtp}
                otp={deleteOtp}
                setOtp={setDeleteOtp}
                handleDeleteConfirm={handleDeleteConfirm}
                pauseConfirmLoading={pauseConfirmLoading}
              />
            )}
          </Box>
        </Box>
        <Box style={{ marginTop: '1rem' }}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={profileData.weeklyReportSubscribe}
                  onChange={handleFormCheckbox}
                  disabled={loading}
                  name={'weeklyReportSubscribe'}
                />
              }
              label={<Typography>Subscribe to Weekly Account Reports</Typography>}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={profileData.monthlyReportSubscribe}
                  onChange={handleFormCheckbox}
                  disabled={loading}
                  name={'monthlyReportSubscribe'}
                />
              }
              label={<Typography>Subscribe to Monthly Account Reports</Typography>}
            />
          </Box>
        </Box>
        <Box marginTop="40px">
          <LoadingButton loading={loading} variant="contained" onClick={handleUpdate}>
            Update Settings
          </LoadingButton>
        </Box>
      </Box>
    )
}

const useStyles = makeStyles(() =>
    createStyles({

        inputBox: {
            width: '100%',
            height: '40px',
            borderRadius: '4px',
            paddingLeft: '10px',
            fontSize: '14px'
        },
        fieldsWrapper:{
            display:'flex',
            alignItems:'center',
            gap:'75px',
            '&> div':{
                flexGrow:'1',
                flexBasis:'0',
            },
            '&> div > div':{
                display:'flex',
                alignItems:'center',
                margin:'16px 0',
                '&>p':{
                    color:'#5f5f5f',
                    fontSize:'14px',
                    marginRight:'8px',
                    width:'100px',
                    color:'#727a82'
                }
            }
        }
    })
)

export default ProfileSettingsPage ;