import React, { useState, useEffect } from 'react';
import { Box, makeStyles, createStyles, Typography, Button, Snackbar } from '@material-ui/core'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
// import { browserHistory } from 'react-router';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
// import AnalyticsPage from './AnalyticsPage';
import Analytics from './Analytics'
import { getGoalsAnalyticsData } from './action'
import { useDispatch, useSelector } from 'react-redux'
import SelectBox from '../../SelectBox'
import { putGoalData } from './action'
import GoalDialogForm from '../GoalDialogForm'
import GoalPageLinks from './GoalPageLinks'
import { goalSchedular } from './action'
import axios from 'axios'
// import AnalyticsScreenSkeleton from '../../../Globals/GlobalComponents/Skeletons/AnalyticsScreenSkeleton';

// const applefont =
//   '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  }
}

const GoalsAnalyticsHeader = (props) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [selectedDuration, setSelectedDuration] = useState('7')
  const [goalName, setGoalName] = useState('')
  const [id, setId] = useState(null)
  const dispatch = useDispatch()
  const [campaignName, setCampaignName] = useState('')
  const [url, setUrl] = useState('')
  const [goalDialog, setGoalDialog] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [goalsData, setGoalsData] = useState('')
  const goalsAnalyticsData = useSelector((state) =>
    state.getIn(['GoalsAnalyticsData', 'goalsAnalyticsData'])
  )
  const goalsAnalytics = useSelector((state) =>
    state.getIn(['GoalsAnalyticsData', 'goalsAnalytics'])
  )
  const goalsSchedular = useSelector((state) =>
    state.getIn(['GoalAnalyticsData', 'goalSchedularData'])
  )
  const profile = useSelector((state) => state.getIn(['profile', 'profile']))
  const [snackbarStatus, setSnackBarStatus] = useState(false)
  const goalSchedularData = useSelector((state) =>
    state.getIn(['GoalFetching', 'goalSchedularData'])
  )
  const [pageLoading, setPageLoading] = useState(true)
  const [linksArray, setLinksArray] = useState([])
  const [goalType, setGoalType] = useState('')
  const [linkUrl, setLinkUrl] = useState('')
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (goalsData) {
      setSelectedDuration(goalsData.dataDuration.toString())
    }
  }, [goalsData])

  const accessLinkClickFeatre = () => {
    const blockedPlans = [
      'price_1HQbLBCkWzi7OrXWJob8bWxU',
      'price_1HiD20CkWzi7OrXWvCZVywSQ',
      'price_1H89N4CkWzi7OrXW1y4N40Ye',
      'price_1H9qZ8CkWzi7OrXWUbIVZRGJ',
    ]
    const planId = profile.plan?.id
    if (blockedPlans.includes(planId)) {
      return false
    } else {
      return true
    }
  }

  const handleDurationChange = (e) => {
    setSelectedDuration(e.value)
    dispatch(goalSchedular(goalsData.trackingId, e.value))
  }

  // update the goals Name
  const handleYourGoal = (e) => {
    setGoalName(e.target.value)
  }

  // update the campaignName
  const handleCampaignName = (e) => {
    setCampaignName(e.value)
  }

  // function to update the url of goal
  const handleUrl = (e) => {
    setUrl(e.target.value)
  }

  // goals put request function
  const handlePostGoalRequest = () => {
    const goals = {
      campaignName: goalsData.campaignName,
      name: goalName,
      url: url,
      id: props.params.id,
      linkUrl: linksArray,
    }
    dispatch(putGoalData(goals, setSnackBarStatus, setGoalsData, goalsData))
    closeHandler()
  }
  //close dialog of goal.
  const closeHandler = () => {
    setGoalDialog(false)
  }

  const handleSnackBarClose = () => {
    setSnackBarStatus(false)
  }
  useEffect(() => {
    setId(props.params.id)
    dispatch(getGoalsAnalyticsData(props.params.id, setGoalsData))
  }, [props.params.id])

  useEffect(() => {
    if (goalSchedularData) {
      goalSchedularData.trackingId === goalsData.trackingId
        ? dispatch(getGoalsAnalyticsData(goalsData._id, setGoalsData))
        : null
    }
  }, [goalSchedularData])

  // useEffect(()=>{

  //     dispatch(goalSchedular(goalsData.trackingId,selectedDuration))
  //     //setSelectedDuration(goalsData.dataDuration)
  // },[selectedDuration])

  const handleEdit = () => {
    if (goalDialog === false) {
      setGoalDialog(true)
      setGoalName(goalsData.name)
      setUrl(goalsData.url)
      setLinksArray(goalsData.linkUrl)
      setGoalType(goalsData.category.value)
    } else setGoalDialog(false)

    handleCloseMenu()
  }

  // close menu list
  const handleCloseMenu = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const handleLinkSeperator = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      setLinksArray([...linksArray, { url: linkUrl, verified: false }])
      handleNewUrlAdd(linkUrl.trim(), linksArray)
      setLinkUrl('')
    }
  }

  const handleNewUrlAdd = async (inputUrl, state) => {
    const res = await axios({
      method: 'GET',
      url: `/micropolicy/webdata?link=${inputUrl}`,
    })
    setLinksArray([...state, { url: inputUrl, verified: res.data.verified }])
    return res.data
  }

  const handleLinkAdd = (e) => {
    setLinkUrl(e.target.value)
  }

  return (
    // <Box padding="5% 0% 0% 5%">
    <Box>
      {/* <AnalyticsScreenSkeleton/>/ */}
      <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1%' }}>
        <Box>
          <Link
            to="/goals"
            style={{
              fontSize: '19px',
              color: '#000',
              lineHeight: '1.6',
              display: 'inline-block',
              paddingTop: '2px',
              fontWeight: '500',
              fontFamily: 'Poppins',
              textTransform: 'capitalize',
            }}
          >
            {`${goalsData.name}'s Summary`}
          </Link>
        </Box>
        <Box style={{ marginRight: '5%' }}>
          <Button
            style={{ width: '100px', height: '33px' }}
            variant="contained"
            onClick={handleEdit}
          >
            Edit
          </Button>
        </Box>
      </Box>
      <Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: '5%',
            borderBottom: '1px solid #c6c6c6',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
          >
            <Tab label="Analytics" classes={{ root: classes.root }} {...a11yProps(0)} />
            {accessLinkClickFeatre() && (
              <Tab label="Links" classes={{ root: classes.root }} {...a11yProps(1)} />
            )}
          </Tabs>
          <Box style={{ padding: '6px 0', width: '98px' }}>
            <SelectBox
              options={[
                { value: '7', label: '7 days' },
                { value: '14', label: '14 days' },
                { value: '28', label: '28 days' },
              ]}
              value={selectedDuration}
              onChange={handleDurationChange}
              className={classes.selectBoxStyle}
            />
          </Box>
        </Box>
        <TabPanel value={value} index={0}>
          <Analytics analyticsData={goalsData} selectedDuration={selectedDuration} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GoalPageLinks
            linksArray={linksArray ? linksArray : goalsData.linkUrl}
            linkClickData={goalsData.linkClickData ? goalsData.linkClickData : []}
          />
        </TabPanel>
      </Box>
      <GoalDialogForm
        open={goalDialog}
        closeHandler={closeHandler}
        handleYourGoal={handleYourGoal}
        handleCampaignName={handleCampaignName}
        handleUrl={handleUrl}
        handlePostGoalRequest={handlePostGoalRequest}
        goalValue={goalName}
        goalUrl={url}
        linksArray={linksArray}
        setLinksArray={setLinksArray}
        goalType={goalType}
        handleLinkSeperator={handleLinkSeperator}
        linkUrl={linkUrl}
        setLinkUrl={setLinkUrl}
        handleLinkAdd={handleLinkAdd}
        accessLinkClickFeatre={accessLinkClickFeatre}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbarStatus}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        message={<span id="message-id">Goal Updated</span>}
      />
    </Box>
  )
}

const useStyles = makeStyles(
    createStyles({
        arrowDiv: {
            width: "32px",
            height: "31px",
            padding: '4px 5px',
            boxSizing: 'border-box',
            border: '1px solid gainsboro',
            cursor: 'pointer',
            marginRight: '9px !important',
            borderRadius: '3px !important',
            backgroundColor: '#fff'
        },
        root: {
            minWidth: "65px !important",
            fontSize: '14px',
            fontWeight: '500',
            color: '#2d2e2c',
            margin: '0 !important',
            textTransform:'capitalize',
            outline:'none',
            '&:focus' : {
                outline:'none'
            }
        },
        selectBoxStyle: {
			fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
        }
    }))

export default GoalsAnalyticsHeader;