import { call, put, fork, takeLatest } from 'redux-saga/effects';
import * as api from 'services/api';
import * as actions from 'ducks/oauth';
import { fetchUserSuccess } from 'ducks/auth';
import { load, loaded } from 'ducks/loading';
import { toast } from 'react-toastify';
import { browserHistory } from 'react-router';
import * as Sentry from '@sentry/browser';
import copy from 'copy-to-clipboard';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2000,
  className: 'toast-style'
};

// const errorMessge = 'Please try again or refresh!';

function* fetch() {
  try {
    yield put(load());
    const res = yield call(api.GET, 'client');
    if (res.error)
      console.log(res.error);
    else
      yield put(actions.createClientOauthSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* create(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'client');
    if (res.error)
      console.log(res.error);
    else
      yield put(actions.successClientOauth(res, action.index));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }

}

function* update(action) {
  try {
    yield put(load());
    const res = yield call(api.PUT, `client/${action.client.id}`, action.client);
    if (res.error)
      console.log(res.error);
    yield toast.error('Client Configuration Saved', toastConfig);
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* deleteClientOauth(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `client/${action.id}`);
    if (res.error)
      console.log(res.error);
    else
      yield put(actions.popClientOauth(action.index));
    browserHistory.push('oauthshow');
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* OauthGetAccessToken(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, `oauth/access_token/${action.requestType}`, action.requestDetails);
    yield toast.error(res.message, toastConfig);
    yield browserHistory.push('/campaigns');
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* generateNewApiKey() {
  try {
    yield put(load());
    const res = yield call(api.GET, 'user/apikey');
    if(res) {
      copy(res.apiKey, {debug:true})
      yield put(fetchUserSuccess(res));
      // yield toast.error('New api key Generated & Copied', toastConfig);
    } else {
      // yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

export function* watchFetch() {
  yield takeLatest(actions.FETCH_CLIENT_OAUTH, fetch);
}

export function* watchCreate() {
  yield takeLatest(actions.CREATE_CLIENT_OAUTH, create);
}

export function* watchUpdate() {
  yield takeLatest(actions.UPDATE_CLIENT_OAUTH, update);
}

export function* watchDelete() {
  yield takeLatest(actions.DELETE_CLIENT_OAUTH, deleteClientOauth);
}

export function* watchOauthGetAccessToken() {
  yield takeLatest(actions.OAUTH_GET_ACCESS_TOKEN, OauthGetAccessToken);
}

export function* watchGenerateNewApiKey() {
  yield takeLatest(actions.GENERATE_NEW_API_KEY, generateNewApiKey);
}

export default function* rootSaga() {
  yield [
    fork(watchFetch),
    fork(watchCreate),
    fork(watchUpdate),
    fork(watchDelete),
    fork(watchOauthGetAccessToken),
    fork(watchGenerateNewApiKey)
  ];
}
