import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import CodeIcon from '@material-ui/icons/Code';
import CustomTooltop from '../../../../Globals/GlobalComponents/CustomTooltip';



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor:'#fff'
	},
	tabRootStyle:{
		// maxWidth:'40px',
		minWidth:'20px',
		border: '1px solid transparent'
	},
	tabsFlexContainer:{
		position:'relative',
		'&:after':{
			content:'""',
			position:'absolute',
			width:'100%',
			height:'1px',
			bottom:'1px',
			backgroundColor:'#d6d8e2'
		}
	},
	tabIndicatorStyle:{
		backgroundColor:'#fff',
		display:'none'
	},
	selectedTabStyle:{
		borderTop:'1px solid #d6d8e2',
		borderLeft:'1px solid #d6d8e2',
        borderRight:'1px solid #d6d8e2',
        borderRadius: theme.shape.borderRadius,
		position:'relative',
		'&:after':{
			content:'""',
			position:'absolute',
			width:'100%',
			height:'1px',
			bottom:'-1px',
			backgroundColor:'#fff',
			height:'2px',
			zIndex:'3'
		}
	}
}));



const navPlatforms = [
  {
    name: 'Code',
    iconType: 'svg',
    icon: <CodeIcon />,
  },
  {
    name: 'Wordpress',
    iconType: 'image',
    icon: `${process.env.REACT_APP_API_URL}/images/wordpress.png`,
  },
  {
    name: 'Woocommerce',
    iconType: 'image',
    icon: `${process.env.REACT_APP_API_URL}/images/woocommerce.png`,
  },
  {
    name: 'Magento',
    iconType: 'image',
    icon: `${process.env.REACT_APP_API_URL}/images/magento.png`,
  },
  {
    name: 'GTM',
    iconType: 'image',
    icon: `${process.env.REACT_APP_API_URL}/images/google.png`,
  },
  {
    name: 'Shopify',
    iconType: 'image',
    icon: `${process.env.REACT_APP_API_URL}/images/shopify.png`,
  },
  {
    name: 'ClickFunnel',
    iconType: 'image',
    icon: 'https://s3.wasabisys.com/influencelogo/dashimages/cf-logo.png',
  },
  {
    name: 'Others',
    iconType: 'svg',
    icon: <AcUnitIcon />,
  },
]

const PixelNavigation =({handleChange, value})=> {
  const classes = useStyles()


  return (
    <AppBar elevation={0} position="static" style={{ backgroundColor: '#fff' }}>
      <Tabs
        classes={{
          flexContainer: classes.tabsFlexContainer,
          indicator: classes.tabIndicatorStyle,
        }}
        value={value}
        onChange={handleChange}
        aria-label="Tabs"
      >
        {navPlatforms.map((platform, index) => (
          <CustomTooltop arrow title={platform.name} placement="top">
            <Tab
              classes={{
                root: classes.tabRootStyle,
                wrapper: classes.tabWrapperStyle,
                selected: classes.selectedTabStyle,
              }}
              label={
                platform.iconType === 'image' ? (
                  <img src={platform.icon} width="19px" height="19px" alt={platform.name} />
                ) : (
                  platform.icon
                )
              }
              {...a11yProps(index)}
              style={{ color: '#082a52' }}
            />
          </CustomTooltop>
        ))}
      </Tabs>
    </AppBar>
  )
}

export default PixelNavigation


export function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}






// import { Tooltip} from '@material-ui/core'


// const useStylesTooltip = makeStyles((theme) => ({
//     arrow: {
//         color: theme.palette.common.black,
//     },
//     tooltip: {
//         backgroundColor: theme.palette.common.black,
//     },
// }));


/**
 * Custom tooltip
 * @param {Object} props Tooltip props
 */
// const CustomTooltop =(props)=> {
//     const classes = useStylesTooltip();
//     return <Tooltip arrow classes={classes} {...props} />;
// }
