import React,{useState,useEffect} from 'react';
import {Box,Paper, createStyles,makeStyles, TableContainer, TableHead, TableRow, TableCell, Table, TableBody,Menu,MenuItem,CircularProgress, Typography} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {
    DialogBox,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogButton
} from '../../Globals/GlobalComponents/DialogBox';
import Moment from 'react-moment';
import { deleteGoalData, putGoalData } from './action';
import { useDispatch } from 'react-redux';
import GoalDialogForm from './GoalDialogForm';
import { browserHistory } from 'react-router';
import {Edit2 , Trash} from '../../img'
import HoverMenu from '../../Globals/GlobalComponents/HoverMenu'
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const applefont = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'


// goals table head data.
const tableHeadData = [
    {
        name: "Goal",
        align: "left"
    },
    {
        name: "Target Path",
        align: "left"
    },
    {
        name: "Type",
        align: "left"

    },
    // {
    //     name: "Views",
    //     align: "center"
    // },
    // {
    //     name: "Hovers",
    //     align: "center"
    // },
    // {
    //     name: "Hover-SignUp",
    //     align: "center"
    // },
    // {
    //     name: "Clicks",
    //     align: "center"
    // },
    // {
    //     name: "Click-SignUp",
    //     align: "center"
    // },
    {
        name: "Conversion(%)",
        align: "center"
    },
    {
        name: "Value($)",
        align: "center"
    },
    {
        name: "Created",
        align: 'center'
    },
    {
        name: '',
        align: 'center'
    }
]

const GoalList = ({ goalData }) => {
    const classes = useStyle();

    // state variable
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const dispatch = useDispatch();
    const [campaignName, setCampaignName] = useState('');
    const [goalName, setGoalName] = useState('');
    const [url, setUrl] = useState('');
    const [goalDialog, setGoalDialog] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [id, setId] = useState(null);


    const handleRouteChangeGoalsAnalytics = (item) => {
        return browserHistory.push(`/goalsanalytics/${item._id}`)
    }

    // update the goals Name
    const handleYourGoal = (e) => {
        setGoalName(e.target.value);
    }

    // update the campaignName
    const handleCampaignName = (e) => {
        setCampaignName(e.value);

    }

    // function to update the url of goal
    const handleUrl = (e) => {
        setUrl(e.target.value);
    }

    // goals put request function
    const handlePostGoalRequest = () => {
        const goals = {
            campaignName: campaignName,
            name: goalName,
            url: url,
            id: id
        }
        dispatch(putGoalData(goals));
        closeHandler();
    }
    //close dialog of goal.
    const closeHandler = () => {
        setGoalDialog(false);
    }

    // // open the menu when hover over the horizontal line.
    // const handleOpenMenu = (e,item) =>{
    //     if(open === true){
    //         setOpen(false);
    //     }else{
    //         setOpen(true);
    //     }
    //     setAnchorEl(e.currentTarget);
    //     setDeleteId(item._id);
    //     //setCampaignName(item.campaign !== undefined ? item.campaign.campaignName : item.campaignName);
    //     setGoalName(item.name);
    //     setUrl(item.url);
    //     setId(item.id);
    //     setCampaignName(item.campaign.campaignNames)

    // }



    // close menu list
    const handleCloseMenu = () => {
        setOpen(false);
        setAnchorEl(null);
    }


    //open edit dialog
    const handleOpenDialog = (id) => {
        setDeleteId(id)
        if (openDialog === false) {
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
        }
        setOpen(false);
    }

    // handle delete request
    const handleDeleteGoal = (id) => {
        dispatch(deleteGoalData(id));
        setLoadingDelete(true);
    }

    // close dialog
    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    // function to change the data format of backend.
    const dateFormatter = (date) => {
        return date.slice(0, 10)
    }

    //update the goal data when click on update.
    // const handleGoalUpdate = (item) => {
    //     if (goalDialog === false) {
    //         setCampaignName(item.campaignName)
    //         setGoalName(item.name)
    //         setUrl(item.url)
    //         setId(item.id)
    //         setGoalDialog(true);
    //     } else setGoalDialog(false);
    //     handleCloseMenu();

    // }

    // loader for delete goaldata.
    useEffect(() => {
        setLoadingDelete(false);
        setOpenDialog(false);
    }, [goalData])
    var cat;
    return (
        <Box className={classes.mainContainer} onMouseEnter={handleCloseMenu}>
            <Box component={Paper} elevation={0}>
                <TableContainer style={{ maxHeight: '650px', padding: "0%", boxShadow: '0px -1px 2px 0px rgba(198, 198, 198, 0.32)' }}>
                    <Table stickyHeader style={{ borderCollapse: 'collapse' }}>
                        <TableHead style={{ borderBottom: '1px solid #e8e8e8', backgroundColor: '#fafbfc' }}>
                            <TableRow>

                                {
                                    tableHeadData.map((item, index) => (
                                        <TableCell
                                            key={index}
                                            style={{ textAlign: item.align }}
                                            // className={classes.tableHead}
                                        >
                                            {item.name}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* data intergation in table body. */}
                            {goalData.length !== 0 ?
                                goalData.map((item, index) => (

                                    <TableRow key={index} className={classes.TableBody}>
                                        <TableCell className={classes.goals}>
                                            {item.name}
                                            <div style={{ fontSize: '11px', color: 'rgba(9,30,66,.76) !important', fontWeight: '400' }}>{item.campaign !== undefined && item.campaign !== null ? item.campaign.campaignName : item.campaign === null ? null : item.campaignName}</div>
                                        </TableCell>
                                        <TableCell className={classes.TableCell}>
                                            {item.url ? item.url : '--'}
                                        </TableCell>

                                        <TableCell className={classes.TableCell} style={{ minWidth: '100px', maxWidth: '100px' }}>
                                            <span className={item.category && (item.category.value === "Purchase" || item.category.value === "Subscribe/Signup") ? classes.purchaseClass : classes.pageVisitClass}>
                                                {item.category ? (item.category && item.category.value).split(" ").join("") : 'PageVisit'}
                                            </span>
                                        </TableCell>

                                        <TableCell align="center" className={classes.TableCell}>
                                            {/*item.conversion? Number.parseFloat(item.conversion).toFixed(2) + "%" : */}
                                            <span className={classes.conversionClass}>
                                                {(item.normalVisitorCount ? (item.influenceVisitorCount / item.normalVisitorCount) * 100 : 0).toFixed(2) + "%"}
                                            </span>
                                        </TableCell>

                                        <TableCell align="center" className={classes.TableCell}>
                                            <span className={classes.valueClass}>
                                                {item.category && (item.category.value === "Purchase" || item.category.value === "Subscribe/Signup") ?
                                                    "$" + (item.conversion && item.influenceVisitorCount ? Number.parseFloat(item.conversion) * Number.parseFloat(item.influenceVisitorCount) : 0).toFixed(2) :
                                                    ("$" + item.normalVisitorCount && item.influenceVisitorCount ? (((item.influenceVisitorCount / item.normalVisitorCount) * 100) * Number.parseFloat(item.influenceVisitorCount)).toFixed(2) : "$" + '0.00')}
                                            </span>
                                        </TableCell>

                                        <TableCell align="center" className={classes.TableCell}>
                                            {/* libary used for change the data format. */}
                                            <Moment format="MMM D, YYYY" date={dateFormatter(item.createdAt)} />
                                        </TableCell>
                                        <TableCell align="center" className={classes.TableCell}>
                                            <HoverMenu>
                                                <CreateIcon onClick={()=>{handleRouteChangeGoalsAnalytics(item)}}/>
                                                <DeleteOutlineIcon onClick={(e)=>{handleOpenDialog(item._id)}}/>
                                            </HoverMenu>
                                        </TableCell>

                                    </TableRow>
                                )) :
                                <TableRow>
                                    <Typography style={{ paddingLeft: '5%', paddingBottom: '5%',paddingTop:'5%',fontSize:'12px', width: "100%" }}>No Goals Available</Typography>
                                </TableRow>

                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* dialog box for delete functionality */}
                <DialogBox onClose={handleDialogClose} open={openDialog}>
                    <DialogTitle onClose={handleDialogClose}>
                        Alert!
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this Goal?
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={handleDialogClose} actionType={"secondary"}>No</DialogButton>
                        <DialogButton  disabled={loadingDelete} actionType={"primary"} onClick={() => handleDeleteGoal(deleteId)}>
                            Yes
                            {/* loader shown when item is deleted */}
                            {loadingDelete && <CircularProgress size={19} className={classes.buttonProgress} />}
                        </DialogButton>
                    </DialogActions>
                </DialogBox>

                {/* edit dialog form */}
                <GoalDialogForm
                    open={goalDialog}
                    closeHandler={closeHandler}
                    handleYourGoal={handleYourGoal}
                    handleCampaignName={handleCampaignName}
                    handleUrl={handleUrl}
                    handlePostGoalRequest={handlePostGoalRequest}
                    goalValue={goalName}
                    goalUrl={url}
                />

            </Box>
        </Box>
    )
}

const useStyle = makeStyles((theme) =>
    createStyles({
        tableHead: {
            padding: '8px 16px !important',
            borderBottom: '1px solid #c9c9c9',
            color: 'rgba(9,30,66,.74)',
            fontWeight: '700 !important',
            fontSize: '10px',
            fontFamily: `${applefont} !important`,
            textTransform: 'uppercase',
        },
        menuItem: {
            "&:hover": {
                backgroundColor: '#cce7fc'
            }
        },
        url: {
            color: 'rgba(9,30,66,.87) !important',
            fontFamily: applefont,
            fontSize: '14px !important',
        },
        goals: {
            color: 'rgba(9,30,66,.87) !important',
            fontFamily: applefont,
            fontSize: '14px',
            textTransform: 'capitalize',
            //padding: '8px'
        },
        TableCell: {
            color: 'rgba(9,30,66,.74) !important',
            fontSize: '14px',
            fontFamily: applefont,
            fontWeight: '400',
            letterSpacing: 'normal'
            //padding: '8px'
        },
        TableBody: {
            borderTop: '1px solid rgba(9,30,66,.12)',
            transition: '0.2s !important',
            "&:hover": {
                backgroundColor: "#ebecf0",
                '& $goals': {
                    color: '#2c5cc5'
                }
            }
        },
        buttonProgress: {
            color: "#2c66c3",
            position: 'absolute',
            top: '55%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        purchaseClass: {
            backgroundColor: '#fbcffc',
            borderRadius: '999px',
            fontSize: '10px',
            padding: '4px 6px',
            fontWeight: '500',
        },

        signupClass: {
            backgroundColor: '#eef9bf',
            borderRadius: '999px',
            fontSize: '9px',
            padding: '4px 6px',
            fontWeight: '500',
        },
        pageVisitClass: {
            backgroundColor: '#deddfa',
            borderRadius: '999px',
            fontSize: '10px',
            padding: '4px 6px',
            fontWeight: '500',
        },

        conversionClass: {
            backgroundColor: '#fafba4',
            borderRadius: '999px',
            fontSize: '11px',
            padding: '4px 6px',
            fontWeight: '500',
        },

        valueClass: {
            backgroundColor: '#d1f7c4',
            borderRadius: '999px',
            fontSize: '11px',
            padding: '4px 6px',
            fontWeight: '500',
        },

        mainContainer:{
            marginTop: '2%'
        },
        [theme.breakpoints.down('md')]:{
            mainContainer: {
                padding:'0'
            },
        }


    }))

export default withRouter(GoalList);
