import axios from 'axios'

export const GET_GENERATED_WEBHOOK = "GET_GENERATED_WEBHOOK"
export const GET_SAVED_DATA_SENDINBLUE = "GET_SAVED_DATA_SENDINBLUE"
export const GET_SAVED_DATA_MAILCHIMP = "GET_SAVED_DATA_MAILCHIMP"
export const GET_SAVED_DATA_AWEBER = "GET_SAVED_DATA_AWEBER"
export const GET_SAVED_DATA_THRIVECART = "GET_SAVED_DATA_THRIVECART"
export const GET_SAVED_DATA_MAILERLITE = "GET_SAVED_DATA_MAILERLITE"
export const GET_SAVED_DATA_LEMLIST = "GET_SAVED_DATA_LEMLIST"
export const GET_SAVED_DATA_GOOGLE = "GET_SAVED_DATA_GOOGLE"
export const GET_SAVED_DATA_STAMPED = "GET_SAVED_DATA_STAMPED"
export const GET_SAVED_DATA_TRUSTPILOT = "GET_SAVED_DATA_TRUSTPILOT"
export const GET_SAVED_DATA_REVIEW = "GET_SAVED_DATA_REVIEW"
export const GET_SAVED_DATA_CAPTERRA = "GET_SAVED_DATA_CAPTERRA"
export const GET_SAVED_DATA_CONVERTKIT = "GET_SAVED_DATA_CONVERTKIT"
export const GET_SAVED_DATA_INTERCOM = "GET_SAVED_DATA_INTERCOM"
export const GET_SAVED_DATA_NETO = "GET_SAVED_DATA_NETO"
export const GET_SAVED_DATA_SHOPIFY = 'GET_SAVED_DATA_SHOPIFY'
export const GET_SAVED_DATA_FACEBOOK = 'GET_SAVED_DATA_FACEBOOK'
export const GET_SAVED_DATA_ACTIVECAMPAIGN = 'GET_SAVED_DATA_ACTIVECAMPAIGN'
export const ADD_FACEBOOK_PAGE = 'ADD_FACEBOOK_PAGE'

export const POST_DATA_SENDINBLUE = "POST_DATA_SENDINBLUE"
export const POST_DATA_MAILCHIMP = "POST_DATA_MAILCHIMP"
export const POST_DATA_AWEBER = "POST_DATA_AWEBER"
export const POST_DATA_THRIVECART = "POST_DATA_THRIVECART"
export const POST_DATA_MAILERLITE = "POST_DATA_MAILERLITE"
export const POST_DATA_LEMLIST = "POST_DATA_LEMLIST"
export const POST_DATA_GOOGLE = "POST_DATA_GOOGLE"
export const POST_DATA_STAMPED = "POST_DATA_STAMPED"
export const POST_DATA_REVIEW = "POST_DATA_REVIEW"
export const POST_DATA_CAPTERRA = "POST_DATA_CAPTERRA"
export const POST_DATA_TRUSTPILOT = "POST_DATA_TRUSTPILOT"
export const POST_DATA_CONVERTKIT = "POST_DATA_CONVERTKIT"
export const POST_DATA_INTERCOM = "POST_DATA_INTERCOM"
export const POST_DATA_NETO = "POST_DATA_NETO"
export const POST_DATA_SHOPIFY = "POST_DATA_SHOPIFY"
export const POST_FACEBOOK = "POST_FACEBOOK"
export const POST_DATA_ACTIVECAMPAIGN = 'POST_DATA_ACTIVECAMPAIGN'



// export const APP_DATA_DELETE_SENDINBLUE = "APP_DATA_DELETE"



export const handleGetData = (apiName, id)=>{
	const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
	return(
		async(dispatch) =>{
			const res = await axios({
				method: 'GET',
				url: `/${apiName}?campaignId=${id}`,
				headers: { 'Authorization': `Bearer ${token}` },
			}).catch(error =>{throw error})
			dispatch(
				apiName === 'sendinblueapi'?
					{type: GET_SAVED_DATA_SENDINBLUE, payload: res.data}:
				apiName === 'mailchimpapi'?
					{type: GET_SAVED_DATA_MAILCHIMP, payload: res.data}:
				apiName === 'aweber'?
					{type: GET_SAVED_DATA_AWEBER, payload: res.data}:
				apiName === 'thrivecart'?
					{type: GET_SAVED_DATA_THRIVECART, payload: res.data}:
				apiName === 'mailerliteapi'?
					{type: GET_SAVED_DATA_MAILERLITE, payload: res.data}:
				apiName === 'lemlist'?
					{type: GET_SAVED_DATA_LEMLIST, payload: res.data}:
				apiName === 'google'?
					{type: GET_SAVED_DATA_GOOGLE, payload: res.data}:
				apiName === 'stamped'?
					{type: GET_SAVED_DATA_STAMPED, payload: res.data}:
				apiName === 'trustpilot'?
					{type: GET_SAVED_DATA_TRUSTPILOT, payload: res.data}:
				apiName === 'review'?
					{type: GET_SAVED_DATA_REVIEW, payload: res.data}:
				apiName === 'capterra'?
					{type: GET_SAVED_DATA_CAPTERRA, payload: res.data}:
				apiName === 'convertkitapi'?
					{type: GET_SAVED_DATA_CONVERTKIT, payload: res.data}:
				apiName === 'intercom'?
					{type: GET_SAVED_DATA_INTERCOM, payload: res.data}:
				apiName === 'netapp'?
					{type: GET_SAVED_DATA_NETO, payload: res.data}:
				apiName === 'shopify'?
					{type: GET_SAVED_DATA_SHOPIFY, payload: res.data}:
				apiName === 'facebook'?
					{type: GET_SAVED_DATA_FACEBOOK, payload: res.data[0]}:
				apiName === 'activecampaignapi'?
					{type: GET_SAVED_DATA_ACTIVECAMPAIGN, payload: res.data}:
				apiName === 'superWebhook'?
					{type: GET_SAVED_DATA_ACTIVECAMPAIGN, payload: res.data}:
				null
			)
			return(res)
		}
	)
}

export const postTableData = (data,id, trackingId, apiName)=>{
	return(
		async(dispatch) =>{
			const res = await axios ({
				method: 'POST',
				url: `/${apiName}/addlist/${trackingId}`,
				data: data
			}).catch(error=> {throw error})
			dispatch(
				apiName === 'sendinblueapi'?
					{type: POST_DATA_SENDINBLUE, payload: res.data}:
				apiName === "mailerliteapi"?
					{type: POST_DATA_MAILERLITE, payload: res.data}:
				apiName === "convertkitapi"?
					{type: POST_DATA_CONVERTKIT, payload: res.data}:
				apiName === "convertkitapi"?
					{type: POST_DATA_CONVERTKIT, payload: res.data}:
				null
			)
		}
	)
}

export const postActivecampaign = (data, trackingId) =>{
	const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
	return(
		async(dispatch) =>{
			const res = await axios ({
				method: 'POST',
				url: `/activecampaignapi/addlist/${trackingId}`,
				headers: { 'Authorization': `Bearer ${token}` },
				data: data
			}).catch(err => {throw err})
			dispatch(
				{type: POST_DATA_ACTIVECAMPAIGN, payload: res.data}
			)
		}
	)
}



export const modifyFacebookPage = (store, index, actionType) =>{
	const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
	actionType === 'add' ? store.pageList[index].isActive = true : store.pageList[index].isActive = false
	return(
		async(dispatch) =>{
			const res = await axios ({
				method: 'PUT',
				url: `/facebook/${store.id}`,
				headers: { 'Authorization': `Bearer ${token}` },
				data: store
			}).catch(err => {throw err})
			dispatch(
				{type: ADD_FACEBOOK_PAGE , payload: res.data}
			)
		}
	)
}


export const postTableDataAux = (data, id, trackingId, apiName) => {
  return async (dispatch) => {
    const res = await axios({
      method: 'POST',
      url: `/${apiName}`,
      data: data,
    }).catch((error) => {
      throw error
    })
    dispatch(
      apiName === 'google'
        ? { type: POST_DATA_GOOGLE, payload: res.data }
        : apiName === 'stamped'
        ? { type: POST_DATA_STAMPED, payload: res.data }
        : apiName === 'review'
        ? { type: POST_DATA_REVIEW, payload: res.data }
        : apiName === 'capterra'
        ? { type: POST_DATA_CAPTERRA, payload: res.data }
        : apiName === 'trustpilot'
        ? { type: POST_DATA_TRUSTPILOT, payload: res.data }
        : apiName === 'intercom'
        ? { type: POST_DATA_INTERCOM, payload: res.data }
        : apiName === 'lemlist'
        ? { type: POST_DATA_LEMLIST, payload: res.data }
        : apiName === 'netapp'
        ? { type: POST_DATA_NETO, payload: res.data }
        : apiName === 'thrivecart'
        ? { type: POST_DATA_THRIVECART, payload: res.data }
        : apiName === 'activecampaignapi'
        ? { type: POST_DATA_ACTIVECAMPAIGN, payload: res.data }
        : null
    )
  }
}

// Shopify redirect action
export const redirectShopify = (id, trackingId, shopUrl) => {
  let redirectUrl = `${process.env.REACT_APP_API_URL}/shopify/oauth/${trackingId}/${id}?shop=${shopUrl}`
  return window.open(redirectUrl, '_blank')
}

// POST call for mailchimp
export const postTableDataMailChimp = (listId, id, trackingId, apiName) =>{
	return(
		async(dispatch) =>{
			const res = await axios ({
				method: "POST",
				url: `/${apiName}/addlist/${trackingId}?list=${listId}&campaignId=${id}`,
				data: {
					list: listId,
					campaignId: id
				}
			}).catch(error => {throw error})
			dispatch(
				{type: POST_DATA_MAILCHIMP, payload: res.data}
			)
		}
	)
}


export const handleAweberAuthCode = (trackingId, id, websiteUrl, apiKey) =>{
	const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
	return(
		async(dispatch) =>{
			const res = await axios ({
				method: 'POST',
				headers: { 'Authorization': `Bearer ${token}` },
				url: '/aweber',
				data: {
					trackingId: trackingId,
					campaignId: id,
					websiteUrl: websiteUrl,
					apiKey: apiKey
				}
			}).catch( error => {throw error})
			dispatch(
				{type: POST_DATA_AWEBER, payload: res.data}
			)
		}
	)
}


export const addFacebook = (data) =>{
	const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    return(
		async(dispatch) =>{
			const res = await axios ({
				method: 'POST',
				headers: { 'Authorization': `Bearer ${token}` },
				url: '/facebook',
				data:{
					trackingId: data.trackingId,
					campaignId: data.campaignId,
					websiteUrl: data.websiteUrl,
					accessToken: data.accessToken,
					userID: data.userID
				}
			}).catch(err => {throw err})
			dispatch(
				{type: POST_FACEBOOK, payload: res.data[0]}
			)
		}
    )
}

export const deleteFacebook = (id) =>{
	const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    return(
		async(dispatch) =>{
			const res = await axios ({
				method: 'DELETE',
				headers: { 'Authorization': `Bearer ${token}`},
				url: `/facebook/${id}`,
			}).catch(err => {throw err})
			dispatch(
				res.status === 200 ? {type: POST_FACEBOOK, payload: undefined}: null
			)
		}
    )
}


export const handleAddAweberList = (data, setListLoading, handleList) =>{
	const authToken = JSON.parse(localStorage.getItem('authToken'));
	const token = authToken ? authToken.token : null;
	setListLoading(true)
    return(
		async(dispatch) =>{
			const res = await axios({
        method: 'PUT',
        headers: { Authorization: `Bearer ${token}` },
        url: `/aweber/${data._id}`,
        data: {
          ...data,
        },
      }).catch((error) => {
        setListLoading(false)
        handleList('')
        throw error
      })
			dispatch({type: POST_DATA_AWEBER, payload: res.data})
			setListLoading(false)
			handleList("")
		}
    )
}

export const handleDeleteAweberList = (campaignId, listId) =>{
	const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;

    return(
		async(dispatch) =>{
			const res = await axios ({
				method: 'DELETE',
				headers: {'Authorization': `Bearer ${token}`},
				url:`/aweber/list/${campaignId}/${listId}`
			}).catch(err => {throw err})
			dispatch({type: POST_DATA_AWEBER, payload: res.data})
		}
    )
}



// Deleting the data of the tables inside the app drawers
export const handleAppDataDelete =(savedDetails, apiName, id) =>{
	return(
		async(dispatch) =>{
			const res = await axios ({
				method: "DELETE",
				url: `/${apiName}/${id}`
			}).catch(error =>{throw error})
			dispatch(
				apiName === 'sendinblueapi'?
					{type: GET_SAVED_DATA_SENDINBLUE, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'mailchimpapi'?
					{type: GET_SAVED_DATA_MAILCHIMP, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'aweber'?
					{type: GET_SAVED_DATA_AWEBER, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'thrivecart'?
					{type: GET_SAVED_DATA_THRIVECART, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'mailerliteapi'?
					{type: GET_SAVED_DATA_MAILERLITE, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'lemlist'?
					{type: GET_SAVED_DATA_LEMLIST, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'google'?
					{type: GET_SAVED_DATA_GOOGLE, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'stamped'?
					{type: GET_SAVED_DATA_STAMPED, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'capterra'?
					{type: GET_SAVED_DATA_CAPTERRA, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'convertkitapi'?
					{type: GET_SAVED_DATA_CONVERTKIT, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'intercom'?
					{type: GET_SAVED_DATA_INTERCOM, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'netapp'?
					{type: GET_SAVED_DATA_NETO, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'activecampaignapi'?
					{type: GET_SAVED_DATA_ACTIVECAMPAIGN, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				apiName === 'superWebhook'?
					{type: GET_GENERATED_WEBHOOK, payload:  savedDetails.filter(item => item._id !== res.data._id )}:
				null
			)

		}
	)

}