import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Popup from 'react-popup';
import { ToastContainer, Zoom } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { checkTokenExists, fetchLogo } from 'ducks/auth';
import { setBreadCrumbs } from 'ducks/breadcrumb';
import { NewHeader, CustomLoading, NewSideBar } from 'components';
// import $ from 'jquery';
// import { checkWhitelable } from 'services/permissions';

import {
  Favicon
} from 'img';

import 'react-popup/style.css';
import './Toast.css';



class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: true,
      disableButton: false,
      style: {},
      openClose: false,
      comment: '',
      help: '',
      errorHelp: '',
      initialStep: 0,
      stepsEnabled: false,
      steps: [
        {
          element: '.red-dot',
          intro: 'Notification',
        }
      ],
      // intercomm:''
    };
    this.textAreaRef = React.createRef();
  }

  componentWillMount() {
    // this.props.fetchCampaignInfo();
    document.body.style = 'background-color:#ebeff3';
    this.checkLogin((err) => {
      if (err)
        browserHistory.push('/login');
    });
    let beamer = document.getElementById('beamerSelector');
    if(beamer)
      beamer.style.display = 'block';

    // if(checkWhitelable()) {
    //   const host = window.location.host;
    //   this.props.fetchLogo(host);
    // }
  }

  componentWillUnmount() {
    document.body.style = 'background-color:white';
    let beamer = document.getElementById('beamerSelector');
    if(beamer)
      beamer.style.display = 'none';
  }

  checkLogin(callback) {
    const cookie = localStorage.getItem('authToken');
    const authToken = cookie
      ? JSON.parse(cookie)
      : null;
    if (authToken) {
      this.props.checkTokenExists(authToken);
      callback();
    } else {
      callback('not logged in');
    }
  }

  componentDidUpdate(e) {
    if (window.innerWidth < 993 && this.props.location.pathname !== e.location.pathname && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
  }

  componentWillReceiveProps(nextProps) {
    let beamer = document.getElementById('beamerSelector');
    if(this.props.children != nextProps.children)
      window.scrollTo(0, 0);
    if(this.props.profile != nextProps.profile) {// || (nextProps.user && nextProps.user.email)
      const user_data = {
        name: nextProps.user.username, // Full name
        email: nextProps.user.email, // Email address
        created_at: nextProps.profile.createdAt, // Signup Date,
        signed_up_at: nextProps.profile.createdAt, // Signup Date,
        planType: nextProps.profile.plan?nextProps.profile.plan.name:'', // Signup Date,
        user_hash: localStorage.getItem('user_hash') // User hash
      };
      if(window && window.Intercom){
        window.Intercom('shutdown');
        window.Intercom('boot', user_data);
      }
      else
        this.setState({intercomm: user_data});
      document.cookie = 'intercom-session-bstu2fsj=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    }
    if(beamer) {
      if(nextProps.campaignInfo)
        beamer.style.display = 'block';
      if(nextProps.profile && nextProps.profile.uniqueVisitorsQoutaLeft <= 0) {
        beamer.style.top = '48px';
        beamer.style.display = 'block';
      }
    }
    // if (this.props.user != nextProps.user) {
    //   if (nextProps.user) {
    //     if (nextProps.user.team && nextProps.user.team.headerScript){
    //       $('head').append(nextProps.user.team.headerScript);
    //     }
    //     else if (checkWhitelable() && nextProps.user.headerScript){
    //       $('head').append(nextProps.user.headerScript);
    //     }

    //     if (nextProps.user.team && nextProps.user.team.footerScript){
    //       $('footer').append(nextProps.user.team.footerScript);
    //     }
    //     else if (checkWhitelable() && nextProps.user.footerScript){
    //       $('footer').append(nextProps.user.footerScript);
    //     }
    //   }
    // }
  }

  openCloseDropdown = () => {
    if(Object.keys(this.state.style).length !== 0)
      this.setState({style: {}});
    else
      this.setState({style: {visibility: 'visible', opacity: 1}});
  }

  openCloseSidebar = () => {
    this.setState({openClose: !this.state.openClose});
  }

  closeDropdown = () => {
    this.setState({style: {}});
  }

  logout = () => {
    document.body.style = 'background-color:white';
    this.openCloseDropdown();
    localStorage.removeItem('authToken');
    browserHistory.push('/');
    // window.Intercom('shutdown');
    // window.Intercom('boot', {
    //   app_id: 'bstu2fsj'
    // });
  }

  handleStateChange = (target, value) => {
    this.setState({[target]: value, errorHelp: ''});
  }


  settings = () => {
    this.openCloseDropdown();
    if(this.state.disableButton)
      return;
    browserHistory.push('/dashboard');
  }

  openProfile = () => {
    this.openCloseDropdown();
    if(this.state.disableButton)
      return;

    let breadcrumb = this.props.breadcrumb;
    breadcrumb = breadcrumb.filter(crumb => crumb.name !== 'Profile Settings');
    breadcrumb.push({
      name: 'Profile Settings',
      path: '/settings'
    });
    this.props.setBreadCrumbs(breadcrumb);

    browserHistory.push('/settings');
  }

  enableStep = () => {
    this.setState({stepsEnabled: true});
  };

  onExit = () => {
    this.setState({stepsEnabled: false});
  };

  render() {
    const {  user, profile, agencylogo } = this.props;
    const { style, openClose, disableButton, errorHelp, steps, stepsEnabled, initialStep } = this.state;
    return (
      <div /*className="dashboard-container"*/>
        {!agencylogo ? (
          <Helmet>
            <title>Influence | Increase Conversion Rate by 17% Using Social Proof!</title>
            <link rel="shortcut icon" href={Favicon} />
          </Helmet>
        ) : (
          <Helmet>
            <title>
              {agencylogo && agencylogo.logoText
                ? agencylogo.logoText
                : 'Influence | Increase Conversion Rate by 17% Using Social Proof!'}
            </title>
            <link
              rel="shortcut icon"
              href={agencylogo && agencylogo.logo ? agencylogo.logo : Favicon}
            />
          </Helmet>
        )}
        <Popup />
        {/* {user && !user.team && !checkWhitelable() && intercomm && <Intercom appID="bstu2fsj" { ...intercomm } />} */}
        <div>
          {(!user || user.size == 0) && (!profile || profile == undefined) && (
            <CustomLoading isLoading={true} />
          )}
          {user && (profile || profile !== undefined) && (
            <React.Suspense fallback={<div>Loading...</div>}>
              <NewSideBar
                steps={steps}
                stepsEnabled={stepsEnabled}
                initialStep={initialStep}
                {...this.props}
                errorHelp={errorHelp}
                renderHelp={this.renderHelp}
                openClose={openClose}
                openCloseSidebar={this.openCloseSidebar}
                disableButton={disableButton}
                onClick={this.closeDropdown}
                enableStep={this.enableStep}
                onExit={this.onExit}
                agencylogo={agencylogo}
              />
            </React.Suspense>
          )}
          {user && (profile || profile !== undefined) && (
            <div style={{ marginLeft: '60px' }}>
              <div>
                <React.Suspense fallback={<div>Loading...</div>}>
                  <NewHeader
                    username={user.userName || user.username}
                    openCloseDropdown={this.openCloseDropdown}
                    logout={this.logout}
                    renderHelp={this.renderHelp}
                    settings={this.settings}
                    openProfile={this.openProfile}
                    dropdownStyle={style}
                    openClose={openClose}
                    openCloseSidebar={this.openCloseSidebar}
                    agencylogo={agencylogo}
                    {...this.props}
                  />
                </React.Suspense>
              </div>

              <div
                style={{ backgroundColor: '#ebeff3', outline: 'none', paddingTop: '25px' }}
                onClick={this.closeDropdown}
              >
                <div>{this.props.children}</div>
              </div>
            </div>
          )}
        </div>
        <ToastContainer hideProgressBar={true} transition={Zoom} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  let campaignInfo = state.getIn(['campaign', 'campaignInfo']);

  return {
    profile: state.getIn(['profile', 'profile']),
    campaignInfo: campaignInfo,
    user: state.getIn(['auth', 'user']),
    agencylogo: state.getIn(['auth', 'agencylogo']),
    breadcrumb: state.getIn(['breadcrumb', 'breadcrumb'])
  };
};

const mapDispatchToProps = {
  checkTokenExists,
  setBreadCrumbs,
  fetchLogo
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DashboardContainer);
