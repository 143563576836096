import axios from 'axios';

export const PUT_SIGNUP = 'PUT_SIGNUP';
export const USER_PIXERL_VERIFY = 'USER_PIXERL_VERIFY';

export const putSignup = (id,websiteUrl,firstName,phoneNumber,email, password, affiliate, plan, trafficCategory, uniqueVisitorQouta, isAppsumoUser, teammateQouta, subuserQouta, codeStack,afterUpdationComplete) => {
    console.log("putSignup")
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    return(
        async(dispatch) => {
            const res = await axios({
                method: 'PUT',
                headers: { 'Authorization': `Bearer ${token}` },
                url: `/user/${id}`,
                data: {
                    websiteUrl,
                    firstName,
                    phoneNumber,
                    email,
                    password,
                    username: email.match(/^(.+)@/)[1],
                    plan: plan,
                    trafficCategory: trafficCategory,
                    uniqueVisitorQouta: uniqueVisitorQouta,
                    isAppsumoUser: isAppsumoUser,
                    teammateQouta: teammateQouta,
                    subuserQouta: subuserQouta,
                    codeStack: codeStack
                  }
            }).catch(err => {throw err})
            dispatch({type: PUT_SIGNUP, payload: res.data})
            afterUpdationComplete()
        }
    );
}



/**
 * Checks the Pixel status of the user irrespective of the campaign created.
 * @param {String} trackingId TrackingId of the user
 */
export const fetchUserPixelStatus = (trackingId, pixelCheckAfterAction)=>{
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    return(
        async(dispatch)=>{
            const res = await axios({
                method: 'GET',
                headers: {'Authorization': `Bearer ${token}`},
                url: `/campaign/pixel/verify?trackingId=${trackingId}`
            }).catch(err=>{
                pixelCheckAfterAction(true, err)
                throw err
            })
            dispatch({type: USER_PIXERL_VERIFY, payload: res.data})
            pixelCheckAfterAction(false, res.data)
        }
    )
}


/**
 *
 * @param {Object} data Object of required data
 * @param {String} data.email emailId of the user
 * @param {String} data.userId userId of the user
 * @param {String} data.path New path of the user
 * @param {Function} fetchUser action for updating user or calling user api again
 * @param {Function} afterAction This funtion will run after the api call get completes
 * @returns Response of the api call
 */
export const userPathChange = (data, fetchUser, afterAction) =>{
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    return(
        async(dispatch)=>{
            const res = await axios({
              method: 'PUT',
              headers: { Authorization: `Bearer ${token}` },
              url: `/user/path/${data.userId}`,
              data: {
                email: data.email,
                path: data.path,
              },
            }).catch((err) => {
              afterAction(true, err)
              throw err
            })
            dispatch(fetchUser)
            afterAction(false, res.data)
            return res
        }
    )
}