
export const FETCH_WEBSITEURL_DATA = 'FETCH_WEBSITEURL_DATA';
export const POST_WEBSITEURL_DATA ='POST_WEBSITEURL_DATA';
export const DELETE_WEBSITEURL_DATA = 'DELETE_WEBSITEURL_DATA';
export const APPS_AND_WEBHOOKS_DATA = "APPS_AND_WEBHOOKS_DATA"
export const GET_GENERATED_WEBHOOK = 'GET_GENERATED_WEBHOOK'
export const GET_APPS_CONNECTED_STATUS = 'GET_APPS_CONNECTED_STATUS'

import axios from 'axios';

export const fetchWebsiteUrl = (campaignInfo,setLoadingData) =>{
    //added a loader
    setLoadingData(true);
    return(
        async(dispatch)=>{
            const response = await axios({
                    method: 'GET',
                    url: `/notificationpath/rules/lead/${campaignInfo.rule._id}`
                    }).catch(error => {throw error})
                    dispatch({ type:FETCH_WEBSITEURL_DATA, payload:response.data });
                    setLoadingData(false);
        }
    )
};

export const postWebsiteUrl = (campaignInfo, url) => {
  //post request
  return async (dispatch) => {
    const response = await axios({
      method: 'POST',
      url: '/notificationpath',
      data: {
        url: url === '/' ? url : `${url}`,
        status: 'unverified',
        class: 'warning',
        type: 'lead',
        rule: campaignInfo.rule._id,
        domain: campaignInfo.websiteUrl,
        campaignName: campaignInfo.campaignName,
        trackingId: campaignInfo.trackingId,
        websiteUrl: campaignInfo.websiteUrl,
      },
    }).catch((error) => {
      throw error
    })
    dispatch({ type: POST_WEBSITEURL_DATA, payload: response.data })
  }
}

// delete request is called
export const deleteWebsiteUrl = (id) => {
  return async (dispatch) => {
    const response = await axios({
      method: 'DElETE',
      url: `/notificationpath/${id}`,
    }).catch((error) => {
      throw error
    })
    dispatch({ type: DELETE_WEBSITEURL_DATA, payload: response.data })
  }
}

const allowedAppsAndWebhooks = [
  'thrivecart',
  'superWebhook',
  'google',
  'facebook',
  'stamped',
  'capterra',
  'shopify',
  'woocommerce',
  'convertkit',
  // 'wordpress',
]
//appsandwebhooks request
export const getAppsAndWebooks = (id, setLoader) => {
  return async (dispatch) => {
    const response = await axios({
      method: 'GET',
      url: `/appsmaster/campaign/${id}`,
    }).catch((error) => {
      throw error
    })
    dispatch({
      type: APPS_AND_WEBHOOKS_DATA,
      payload: response.data.filter((item) => {
        return allowedAppsAndWebhooks.includes(item.keyName)
      }),
    })
    if (setLoader) {
      setLoader(false)
    }
  }
}

export const getGeneratedWebHook = (id) => {
  const authToken = JSON.parse(localStorage.getItem('authToken'))
  const token = authToken ? authToken.token : null
  return async (dispatch) => {
    const res = await axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
      url: `/webhooks/campaign/${id}`,
    }).catch((err) => {
      throw err
    })
    dispatch({ type: GET_GENERATED_WEBHOOK, payload: res.data })
  }
}

export const getGeneratedWebHook1 = (id) => {
  const authToken = JSON.parse(localStorage.getItem('authToken'))
  const token = authToken ? authToken.token : null
  return async (dispatch) => {
    const res = await axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
      url: `/webhooks/campaign/${id}`,
    }).catch((err) => {
      throw err
    })
    dispatch({ type: GET_GENERATED_WEBHOOK, payload: res.data })
    handleWebhookList(id, res.data)
  }
}

export const getAppsConnectedStatus = (id) => {
  const authToken = JSON.parse(localStorage.getItem('authToken'))
  const token = authToken ? authToken.token : null
  return async (dispatch) => {
    const res = await axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
      url: `/appsconnectedstatus?campaignId=${id}`,
    }).catch((err) => {
      throw err
    })
    dispatch({ type: GET_APPS_CONNECTED_STATUS, payload: res.data })
  }
}

const handleWebhookList = async (id, webhooks) => {
  const authToken = JSON.parse(localStorage.getItem('authToken'))
  const token = authToken ? authToken.token : null
  const webhook_list = []
  webhooks.map((item) => webhook_list.push(item._id))

  const response = await axios({
    method: 'PUT',
    headers: { Authorization: `Bearer ${token}` },
    url: `/campaign/${id}`,
    data: {
      id: `${id}`,
      singleCampaign: true,
      webhook_list: webhook_list,
    },
  }).catch((err) => {
    throw err
  })
}
